<template>
  <div id="ConceptView" class="w-screen h-screen select-none overflow-x-hidden bg-gray-900 relative">
    <div class="gradient-bottom">
      <nav-bar></nav-bar>
      <concept-section></concept-section>
      <footer-section></footer-section>
    </div>

  </div>
</template>

<script>
import footerSection from "@/components/sections/footerSection";
import navBar from "@/components/sections/navBar";
import conceptSection from "@/components/sections/conceptSection";

export default {
  name: 'ConceptView',
  components: {
    footerSection,
    navBar,
    conceptSection,
  }
}
</script>

<style scoped>

.gradient-top {
  background: rgb(0,0,0);
  background: linear-gradient(45deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
}

.gradient-bottom {
  background: rgb(0,0,0);
  background: linear-gradient(135deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
  background-size: 100% 50rem;
  background-repeat: no-repeat;
}

@media (min-width: 1536px) {
  .gradient-bottom {
    background-size: 100%;
    background-repeat: repeat;
  }
}

</style>