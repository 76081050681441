<template>
  <div id="coreFeatures" class="font-montserrat w-full h-fit 2xl:h-full flex flex-col place-content-center place-items-center pt-10 px-6 2xl:px-52 pb-20">
    <h1 class="text-gray-200 text-2.5xl 2xl:text-6xl w-full 2xl:h-48 h-24 flex place-items-center place-content-start font-semibold">Das macht uns aus<span class="italic">!</span></h1>
    <div id="featureCardContainer" class="hidden w-full h-full 2xl:grid grid-cols-3 grid-rows-2 gap-4 gap-12 place-content-center place-items-center ">
      <feature-card v-show="cards.show" @click="highlightFeature('character')" :image="this.features.character.image">Individualisierung des Charakters</feature-card>
      <feature-card v-show="cards.show" @click="highlightFeature('factions')" :image="this.features.factions.image">Fraktionen und Zusammenschlüsse</feature-card>
      <feature-card v-show="cards.show" @click="highlightFeature('business')" :image="this.features.business.image">Unternehmen</feature-card>
      <feature-card v-show="cards.show" @click="highlightFeature('crafting')" :image="this.features.crafting.image">Crafting und Kochen</feature-card>
      <feature-card v-show="cards.show" @click="highlightFeature('nightlife')" :image="this.features.nightlife.image">Nachtleben</feature-card>
      <feature-card v-show="cards.show" @click="highlightFeature('activities')" :image="this.features.activities.image">Freizeitaktivitäten</feature-card>

      <feature-page v-show="page.show" :image="this.page.image" @exit="showFeaturePage(false)" class="col-span-3 row-span-2">
        <template v-slot:title>{{this.page.title}}</template>
        <template v-slot:text><p class="text-xl text-gray-300">{{this.page.text}}</p></template>
      </feature-page>
    </div>

    <div class="flex 2xl:hidden w-full ">
      <div class="accordion flex flex-col gap-4 w-full" id="accordionExample">

        <div v-for="(feature, index) in features" class="accordion-item bg-glass">
          <h2 class="accordion-header mb-0" :id="'header' + index">
            <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
              {{ feature.title }}
            </button>
          </h2>
          <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'header' + index">
            <div class="accordion-body py-4 px-5 text-lg text-gray-200">
              {{ feature.text }}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import featureCard from "@/components/featureCard";
import featurePage from "@/components/featurePage";
export default {
  name: "coreFeatures",
  components: {
    featureCard,
    featurePage,
  },
  data(){
    return {
      features: {
        character: {
          image: "core-features/charcreator_blur_compressed.jpg",
          title: 'Individualisierung',
          text: 'Behutsam kümmerst du dich um deine neue und noch unbekannte Pflanzen. Dein grüner Daumen lässt dich niemals im Stich. Aber wie war das noch gleich? Da musst du erst mal im Buch nachschlagen. Du beneidest Menschen, die einfach Genies sind und das ganze Wissen in sich aufsaugen wie die Pflanzen Wasser. Aha! Wasser. Die Pflanze braucht täglich Wasser. Jeder hat seine Fähigkeiten, in denen er einfach gut ist. Ein Studium ist nie verkehrt, um sein Wissen zu erweitern und immer besser zu werden, denn umso besser du bist, desto bessere Ergebnisse kannst du erzielen. Jeder Mensch ist einzigartig. Auch du!',
        },
        factions: {
          image: "core-features/police_compressed.jpg",
          title: 'Fraktionen',
          text: 'Du bist mit deinem Kollegen auf dem Weg zu einem Einsatz - Verkehrsunfall. Gleichzeitig mit dem Fire Department erreicht ihr die Unfallstelle: Massenkarambolage. Eine Person ist schwer verletzt und muss mit ins Krankenhaus, dort wird sie operiert. Nach der Operation kommt das Police Department auf euch zu. Die Person wird gesucht, sie ist eine bekannte Sektenführerin - Epsilon. Das Police Department beobachtet sie schon länger. Ihre Spuren führen bis in Darknet. Dummerweise nutzte sie keinen VPN und somit hatten die Polizisten ein leichtes Spiel. Sie ist aber nur die Spitze des Eisberges. Kifflom!',
        },
        business: {
          image: "core-features/farming_compressed.jpg",
          title: 'Unternehmen',
          text: 'Die Sonne geht auf, du ziehst dir deine Gummistiefel an und marschierst zu deinen Kühen. Sie warten schon sehnlich auf dich, endlich gibts Futter! Dir fällt ein, dass der Hühnerstall wieder ausgemistet werden muss. Fix rufst du deinen Mitarbeiter an und bestellst ihn zu dir, er soll für dich die Aufgaben erledigen. Dein Handy macht auf sich aufmerksam, der Auftrag von Betty ist heute fällig. Es müssen 50 kg Kartoffeln zu Burger Shot geliefert werden. Da du nicht ausliefern kannst, versuchst du dir per InSight App einen Lieferanten zu organisieren. Glück gehabt! Um 12 Uhr ist er bei dir.',
        },
        crafting: {
          image: "core-features/burgershot2_cut_compressed.jpg",
          title: 'Crafting & Kochen',
          text: 'Mühsam schraubst du die letzten Teile deines Sturmgewehrs zusammen, befüllst das Magazin mit Munition, lädst die Waffe und drückst sie deinem Kumpel Dylan in die Hand. Er vertickt das Teil weiter und stopft sich die Taschen voll. Francesco, ein Freund von dir, besitzt ein italienisches Restaurant. Dort kocht er täglich frisch mit nur den besten und qualitativ hochwertigsten Zutaten der Stadt. Du weißt, Dylan geht dort gerne Essen. Deswegen bittest du Francesco, Drogen ins Essen von Dylan zu mischen, eine tödliche Dosis.',
        },
        nightlife: {
          image: "core-features/nightlife_light_compressed.jpg",
          title: 'Nachtleben',
          text: 'Die Dunkelheit bricht ein. Du steigst in deinen illegal getunten Elegy Retro Custom. Die Motoren deiner Gegner heulen auf und ihr macht die Straßen von Los Santos unsicher. Euer Ziel: Das Tuning Treffen am anderen Ende der Stadt. Egal ob legal, illegal oder Serie. Dort wirst du schöne Fahrzeuge sehen. Das ist dein Leben! Andere schließen gerade einen Drogendeal ab, legen als DJ auf einer Party auf oder genießen Cocktails in einer Bar. Die Nacht ist noch jung. Wofür wirst du dich entscheiden?',
        },
        activities: {
          image: "core-features/camping_cut_compressed.jpg",
          title: 'Freizeitaktivitäten',
          text: 'Was gibt es Besseres als nach einem harten Arbeitstag sich mit seinen Freunden zu treffen, seinem Hobby nachzugehen oder einfach zu entspannen. Angeln, Grillparty bei Freunden, am Projekt Auto schrauben, sich um sein Bienenvolk kümmern, Fallschirmspringen, Segeln, eine Runde Dart in der Bar oder ein gemütlicher Abend mit einem Bier am Strand. Ganz egal, wonach dir der Sinn steht. Los Santos bietet dir viele Möglichkeiten, dich auch in deiner Freizeit zu beschäftigen ohne das es langweilig wird',
        },
      },
      cards: {
        show: true
      },
      page: {
        show: false,
        image: "core-features/nightlife_light_compressed.jpg",
        title: '',
        text: '',
      }
    };
  },
  methods: {
    highlightFeature(type)
    {
      switch (type) {
        case 'character':
          this.page.image = this.features.character.image;
          this.page.title = this.features.character.title;
          this.page.text = this.features.character.text;
          break;
        case 'factions':
          this.page.image = this.features.factions.image;
          this.page.title = this.features.factions.title;
          this.page.text = this.features.factions.text;
          break;
        case 'business':
          this.page.image = this.features.business.image;
          this.page.title = this.features.business.title;
          this.page.text = this.features.business.text;
          break;
        case 'crafting':
          this.page.image = this.features.crafting.image;
          this.page.title = this.features.crafting.title;
          this.page.text = this.features.crafting.text;
          break;
        case 'nightlife':
          this.page.image = this.features.nightlife.image;
          this.page.title = this.features.nightlife.title;
          this.page.text = this.features.nightlife.text;
          break;
        case 'activities':
          this.page.image = this.features.activities.image;
          this.page.title = this.features.activities.title;
          this.page.text = this.features.activities.text;
          break;
      }

      this.showFeaturePage(true);
    },
    showFeaturePage(state)
    {
      this.page.show = state;
      this.cards.show = !state;
    }
  }
}
</script>

<style scoped>



#coreFeatures {
  background: rgb(0,0,0);
  background: linear-gradient(225deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
  background-size: 100% 50rem;
  background-repeat: no-repeat;
}

@media (min-width: 1536px) {
  #coreFeatures {
    background: rgb(0,0,0);
    background: linear-gradient(45deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
  }
}


.accordion-button:not(.collapsed) {
  color: #fff;
  background: linear-gradient(to bottom right, #662d91, #821690);
  box-shadow: none;
}

/*.accordion-item:first-of-type .accordion-button {*/

/*}*/

/*.accordion-item:last-of-type .accordion-button.collapsed {*/

/*}*/

/*.accordion-item:last-of-type:not(.collapsed) {*/
/*  margin-bottom: 4rem;*/
/*}*/

/*.accordion-item .accordion-button {*/
/*  border-radius: calc(0.5rem - 1px);*/
/*  !*border-top-right-radius: calc(0.5rem - 1px);*!*/
/*}*/

.accordion-button:not(.collapsed)::after {
  filter: brightness(200);
}

.accordion-button::after {
  filter: brightness(200);
}

.bg-glass {
  /* From https://css.glass */
  background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5rem);
  /*-webkit-backdrop-filter: blur(5px);*/
}

.gradient-top {
  background: rgb(0,0,0);
  background: linear-gradient(225deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
}


</style>