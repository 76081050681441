<template>
  <div class="w-full text-gray-200 text-lg pt-32 pb-16 px-6 2xl:px-52">
    <h1 class="text-gray-200 text-4xl 2xl:text-6xl w-full pb-8 flex place-items-center place-content-start font-semibold">Unser Konzept</h1>
    <p>
      Wir sind nicht der nächste Roleplay Server mit dem gleichen Script, der gleichen UI, mit den gleichen Regeln, Features, Ideen und Konzepten. Mit Lumina - Roleplay wollen wir euch eine Welt voller neuer Möglichkeiten bieten, in einen fiktiven Charakter zu schlüpfen und eurer Kreativität freien Lauf zu lassen. Entfaltet euren Charakter frei und lebt die Geschichte, die ihr leben wollt, ohne durch unnötige Serverregeln in die Schranken gewiesen zu werden. Natürlich gibt es einige Regeln, um ein friedliches Miteinander zu fördern. Diese sollen aber das Roleplay so wenig wie möglich beeinträchtigen.
      <br><br>
      Wir legen großen Wert auf Gleichberechtigung. Jeder Spieler soll bei uns die Möglichkeit bekommen, Roleplay spielen zu können, wie er es gerne möchte. Egal ob Anfänger, Menschen mit Einschränkungen jeglicher Art, Eltern oder Berufstätige mit wenig Zeit. Niemand soll etwas verpassen oder das Gefühl haben “hinterher zu hängen", weil man nicht 24 Stunden, 7 Tage die Woche online sein kann. Mit Gleichberechtigung wollen wir ebenfalls das Roleplay gestalten. Jeder Spieler, der in Los Santos ist, ist ein Bürger. Egal ob Teammitglied oder nicht. Bei Unstimmigkeiten wird mit dem Support gesprochen und nach einer Lösung gesucht, mit der am Ende jede Partei zufrieden ist. Wir wissen, dass das schwer ist und auch nicht immer möglich sein wird. Wir werden uns trotzdem bemühen!
      <br><br>
      Das Ziel ist es, am Ende ein Projekt zu haben, auf dem sich Spieler wohlfühlen und immer wieder gerne zurückkehren. Mit regelmäßigen, teils Themenbasierten Updates soll der Server aktuell und spannend gehalten werden. Events bringen Abwechslung im Alltag und schaffen neue Erinnerungen.
    </p>

    <h2 class="text-2xl font-semibold pt-12 pb-2">Lokation und GTA - Lore</h2>
    <p>
      Unser Projekt hat es sich zur Aufgabe gemacht, der GTA - Lore treu zu bleiben. Wir bespielen den an Kalifornien angelehnten Staat San Andreas. Schauplatz eures Roleplays ist das Los Angeles Pendant Los Santos. Echte Automarken, Firmen, Gangs, Gebäude oder Personen wirst du bei uns nicht finden. Wir wissen natürlich, dass Mod-Fahrzeuge sehr gefragt sind. Deshalb haben wir eine Liste von Lore-freundlichen Fahrzeugen zusammengestellt. Das Stichwort lautet "Vanilla Edits”.
    </p>

    <h2 class="text-2xl font-semibold pt-12 pb-2">Das Roleplay / Gameplay Konzept</h2>
    <p>
      Realismus ist eines unserer Aushängeschilder. Das Roleplay auf Lumina soll so realistisch wie möglich gestaltet werden, ohne es unnötig kompliziert zu machen. Dabei helfen dir einige Features wie z.B. ein realistisches Schadenverhalten von Fahrzeugen und Menschen. Langweilige und eintönige Jobs wird es bei uns nicht geben. Abwechslung und Spannung sollen den Spieler motivieren. Unternehmen spielen bei uns eine große Rolle. Wir wollen möglichst viel in die Hände von Spielern geben. Vollständig auf NPCs verzichten können und wollen wir, besonders zu Beginn, aber nicht. Spieler erhalten Handlungsspielraum und können fast jedes Unternehmen eröffnen. Hierbei spielt es keine Rolle, ob die passenden Items oder Fahrzeuge fehlen. Spieler können Konzepte einreichen und wir werden versuchen diese umzusetzen. Somit können wir Items und Fahrzeuge implementieren, die es zuvor nicht gab. Im Rahmen unserer Regeln und den Möglichkeiten die uns Alt:V bietet. Mit einer Vielfalt an Items, Spielmechaniken, Gebäuden und Designs geben wir dir die Möglichkeit sein zu können, wer auch immer du sein willst Food Truck Inhaber, Drogenboss, Landwirt, Obdachlos, Pizzabäcker, Leiter eines Logistikzentrums, Tankstellenbetreiber, Kerzenhersteller, Blumen Fachverkäufer, Imker, Eventplaner, Führer einer Sekte, Schwarzmarkthändler, oder was auch immer euer Herz begehrt.
      <br><br>
      Wir versuchen stets interessante, neue Features für euch zu entwickeln. Feedback zu vorhandenen Features und coole neue Ideen sind dementsprechend immer erwünscht! Am Ende können wir aber das Rad nicht neu erfinden. Manchmal ist die am weitesten verbreitete Umsetzung tatsächlich die beste.
    </p>

    <h2 class="text-2xl font-semibold pt-12 pb-2">Die Wirtschaft</h2>
    <p>
      Im echten Leben dreht sich alles um Geld. Das ist in Los Santos nicht anders. Unsere Wirtschaft ist realistisch und dynamisch. Preise schwanken je nach Anfrage und Angebot. Lebensmittel, Fahrzeuge, Häuser, Wohnungen, Hotels und Gegenstände haben faire Preise und werden regelmäßig angepasst. Spieler können durch verschiedene Jobs ihren Lebensunterhalt verbessern. Aber auch ohne Beruf sichern wir das Überleben der Spieler mit einem Grundeinkommen. Uns ist es wichtig, dass die Spieler sich primär auf das Roleplay konzentrieren können und sich wenig Sorgen um ihr Überleben machen müssen. Spieler sollen sich nicht unnötig gestresst fühlen, wenn sie ein Unternehmen leiten oder ihrem Job nachgehen. Spaß und Roleplay haben höchste Priorität.
      <br><br>
      Wir wollen aber auch nicht zur nächsten Wirtschaftssimulation werden. Das Roleplay steht weiterhin im Vordergrund. Unsere Spieler sollen Spaß haben und nicht nur auf einem Feld rumstehen und “E” drücken.
    </p>

    <h2 class="text-2xl font-semibold pt-12 pb-2">Cops & Gangs (Zusammenschlüsse)</h2>
    <p>
      San Andreas ist ein ausgewogener Staat. Auf einen Cop-Staat oder Gang-Staat hat niemand Lust. Uns ist es wichtig, dass jeder die gleichen Chancen hat. Verlieren ist zwar nicht leicht, aber fair. Die Gangs werden Möglichkeiten haben ihr Geld zu verdienen, wie es Gangs nunmal machen - mit kriminellen Geschäften. Die Cops werden Möglichkeiten haben, Gangs aufzuspüren, ohne Power Roleplay zu erzwingen. Cops und Gangster sollen Handlungsspielraum haben und Spaß daran haben, zur jeweiligen Fraktion zu gehören und diese zu bekämpfen.
      <br><br>
      Wir schreiben euch nicht vor, was für ein Zusammenschluss ihr sein wollt. Von Gang über Mafia bis hin zur Sekte ist vieles möglich.
    </p>

    <h2 class="text-2xl font-semibold pt-12 pb-2">Community</h2>
    <p>
      Die Community ist das A und O. Wir legen viel Wert auf eure Meinung. Die Community leistet einen großen Beitrag. In unserem Discord (Coming Soon) könnt ihr Wünsche, Anregungen, Verbesserungsvorschläge und sachliche Kritik äußern. Wir werden uns alles zu Herzen nehmen und bestmöglich umsetzen. Mit regelmäßigen Umfragen wollen wir eure Meinung zu verschiedenen Themen herausfinden und unsere Features entsprechend verbessern. Wir informieren euch natürlich auch regelmäßig über den aktuellen Entwicklungsstand. Der Server und die Community sollen zu einem sicheren Ort heranwachsen, an dem ihr der Realität entfliehen könnt.
    </p>

    <h2 class="text-2xl font-semibold pt-12 pb-2">Das technische Konzept</h2>
    <p>
      Wir verzichten komplett auf vorgefertigte Scripts. Nur so können wir unseren hohen Ansprüchen gerecht werden. Unsere liebevoll gestaltete UI gibt dir das Gefühl, als wärst du wirklich in San Andreas - nicht am heimischen Computer. Alle Items sind eigenständig designt und implementiert. Mit einer Vielfalt an Items bieten wir euch unzählige Möglichkeiten.
    </p>

    <h2 class="text-2xl font-semibold pt-12 pb-2">Wir suchen Dich!</h2>
    <p>
      Um all unsere Ideen umsetzen zu können, benötigen wir motivierte Unterstützung, die Zeit und Lust hat, anstehende Aufgaben zu bewältigen, Konzepte und Ideen mit einzubringen oder uns anderweitig zu helfen. Für alle Bereiche musst du mindestens das 18. Lebensjahr erreicht haben.
    </p>
    <h2 class="text-2xl font-semibold pt-12 pb-2">Wenn du uns helfen möchtest, schaue dir <router-link to="/voluntary" class="magic">unsere offenen Positionen</router-link> an!</h2>
  </div>
</template>

<script>
export default {
  name: "impressumSection"
}
</script>

<style scoped>
a {
  text-decoration: underline #C50F85;
}

a:hover {
  text-decoration: none;
  color: #C50F85;
}

</style>