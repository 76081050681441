<template>
  <div class="w-screen h-screen select-none overflow-x-hidden bg-gray-900 relative">
    <nav-bar></nav-bar>
    <faq-section></faq-section>
    <footer-section></footer-section>
  </div>
</template>

<script>
import navBar from "@/components/sections/navBar";
import footerSection from "@/components/sections/footerSection";
import faqSection from "@/components/sections/faqSection";

export default {
  name: 'FAQView',
  components: {
    navBar,
    footerSection,
    faqSection
  }
}
</script>