<template>
  <div class="w-full min-h-full text-gray-200 text-lg pt-32 pb-16 px-6 2xl:px-52 gradient-top">
    <h1 class="text-gray-200 text-4xl 2xl:text-6xl w-full pb-8 flex place-items-center place-content-start font-semibold">Allgemeines FAQ</h1>

    <div class="accordion flex flex-col gap-6" id="accordionExample">

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingOne">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            Was ist Lumina - Roleplay?
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne">
          <div class="accordion-body py-4 px-5 text-lg">
            Lumina - Roleplay ist ein auf Alt:V basierender, Grand Theft Auto V hardcore Roleplay Server.
          </div>
        </div>
      </div>

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingTwo">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Wie alt muss ich sein, um auf Lumina spielen zu dürfen?
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo">
          <div class="accordion-body py-4 px-5 text-lg">
            Das Mindestalter zum Spielen beträgt 18 Jahre.
          </div>
        </div>
      </div>

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingThree">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            Ist der Server anfängerfreundlich?
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree">
          <div class="accordion-body py-4 px-5 text-lg">
            Ja! Mit unserem internen Wiki können auch Roleplay Anfänger sich in das hardcore Roleplay einfinden. <br> Wir sehen davon ab, Anfänger bei kleineren Fehlern zu bestrafen oder zu bannen. Bei einem Gespräch wird besprochen, worin die Fehler liegen und wir versuchen, diese zu beseitigen. <br> Jeder, der bereit ist, dazu zu lernen und sich Mühe gibt, ist herzlich willkommen. Hab keine Angst und trau dich an was Neues!
          </div>
        </div>
      </div>

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingFour">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            Ist der Server barrierefrei?
          </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour">
          <div class="accordion-body py-4 px-5 text-lg">
            Wir gestalten den Server so, dass auch Menschen mit Beeinträchtigungen bei uns spielen können.
          </div>
        </div>
      </div>

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingFive">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            Ist der Server für Eltern und Berufstätige geeignet?
          </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive">
          <div class="accordion-body py-4 px-5 text-lg">
            Ja, wir nehmen auf alle Spieler Rücksicht.
          </div>
        </div>
      </div>

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingSix">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            Was ist hardcore Roleplay?
          </button>
        </h2>
        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix">
          <div class="accordion-body py-4 px-5 text-lg">
            Das Roleplay auf Lumina soll so realistisch wie möglich gestaltet werden und somit auch dein Charakter. Nehmt euren Charakter und das Roleplay ernst.
          </div>
        </div>
      </div>

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingSeven">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
            Wird es eine Alpha geben und wann startet der Server für alle?
          </button>
        </h2>
        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven">
          <div class="accordion-body py-4 px-5 text-lg">
            Bisher ist geplant, dass wir fünf Phasen durchgehen.
            <ul class="list-decimal pl-6 py-2">
              <li>
                Closed Alpha (nur für ausgewählte Spieler)
              </li>
              <li>
                Open Alpha (für jeden)
              </li>
              <li>
                Closed Beta (nur für ausgewählte Spieler)
              </li>
              <li>
                Open Beta (für jeden)
              </li>
              <li>
                Release (für jeden)
              </li>
            </ul>
            Zur closed Beta kann sich eine bestimmte Anzahl an Spieler bewerben und daran teilnehmen. <br> Wie lange die jeweiligen Phasen dauern und wann der Server endgültig startet, können wir zum aktuellen Zeitpunkt nicht sagen. <br><br> Neue Informationen findet ihr im <a class="link highlight font-bold" href="https://discord.gg/mjjDc6ZrqM">Discord</a>.
          </div>
        </div>
      </div>

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingEight">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
            Wo spielt Lumina?
          </button>
        </h2>
        <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight">
          <div class="accordion-body py-4 px-5 text-lg">
            Wir wollen mit Lumina Roleplay der GTA - Lore treu bleiben. Schauplatz eures Roleplays wird Los Santos sein.
          </div>
        </div>
      </div>

      <div class="accordion-item bg-glass">
        <h2 class="accordion-header mb-0" id="headingNine">
          <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
            Wird es Import Fahrzeuge geben?
          </button>
        </h2>
        <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine">
          <div class="accordion-body py-4 px-5 text-lg">
            Wir wissen natürlich, dass Mod-Fahrzeuge sehr gefragt sind. Deshalb haben wir eine Liste von Lore-freundlichen Fahrzeugen zusammengestellt.
          </div>
        </div>
      </div>




    </div>

  </div>

</template>

<script>

export default {
  name: "faqSection",
}
</script>

<style scoped>
.accordion-button:not(.collapsed) {
  color: #fff;
  background: linear-gradient(to bottom right, #662d91, #821690);
  box-shadow: none;
}

a.link  {
  display: inline-block;
  position: relative;
}

a.link.highlight:after {
  content: '';
  position: absolute;
  width: 100%;
  /*transform: scaleX(0);*/
  height: 0.2rem;
  bottom: -0.1rem;
  left: 0;
  background-color: #C50F85;
  /*transform-origin: bottom right;*/
  transition: transform 0.25s ease-out;
  border-radius: 1rem;
}

/*a.link.highlight:hover:after {*/
/*  transform: scaleX(1);*/
/*  transform-origin: bottom left;*/
/*}*/

/*.accordion-item:first-of-type .accordion-button {*/

/*}*/

/*.accordion-item:last-of-type .accordion-button.collapsed {*/

/*}*/

/*.accordion-item:last-of-type:not(.collapsed) {*/
/*  margin-bottom: 4rem;*/
/*}*/

/*.accordion-item .accordion-button {*/
/*  border-radius: calc(0.5rem - 1px);*/
/*  !*border-top-right-radius: calc(0.5rem - 1px);*!*/
/*}*/

.accordion-button:not(.collapsed)::after {
  filter: brightness(200);
}

.accordion-button::after {
  filter: brightness(200);
}

.bg-glass {
  /* From https://css.glass */
  background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5rem);
  /*-webkit-backdrop-filter: blur(5px);*/
}

.gradient-top {
  background: rgb(0,0,0);
  background: linear-gradient(225deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
  background-size: 100% 50rem;
  background-repeat: no-repeat;
}

@media (min-width: 1536px) {
  .gradient-top {
    background-size: 100%;
    background-repeat: repeat;
  }
}
</style>