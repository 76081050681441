<template>
  <div id="jobView" class="w-screen h-full select-none overflow-x-hidden bg-gray-900 relative">
    <div class="gradient-top">
      <nav-bar></nav-bar>
      <what-we-offer></what-we-offer>
      <job-disclaimer></job-disclaimer>
      <inbetween-headline position="center">
        <template v-slot:text>Wir freuen uns auf&nbsp;<span class="magic">dich</span><span class="italic">!</span></template>
      </inbetween-headline>
    </div>

    <div class="gradient-bottom 2xl:h-full">
      <job-section></job-section>
      <inbetween-headline>
        <template v-slot:text><span class="font-montserrat text-center">Wir freuen uns auf&nbsp;<span class="magic">dich</span><span class="italic">!</span></span></template>
      </inbetween-headline>
    </div>

    <footer-section></footer-section>
  </div>
</template>

<script>
import footerSection from "@/components/sections/footerSection";
import navBar from "@/components/sections/navBar";
import jobSection from "@/components/sections/jobSection";
import inbetweenHeadline from "@/components/inbetweenHeadline";
import whatWeOffer from "@/components/sections/whatWeOffer";
import jobDisclaimer from "@/components/sections/jobDisclaimer";
import jobContact from "@/components/sections/jobContact";

export default {
  name: 'HomeView',
  components: {
    footerSection,
    navBar,
    jobSection,
    inbetweenHeadline,
    whatWeOffer,
    jobDisclaimer,
    jobContact
  }
}
</script>

<style scoped>

.gradient-top {
  background: rgb(0,0,0);
  background: linear-gradient(225deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
  background-size: 100% 50rem;
  background-repeat: no-repeat;
}

@media (min-width: 1536px) {
  .gradient-top {
    background: rgb(0,0,0);
    background: linear-gradient(45deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
    background-size: 100%;
    background-repeat: repeat;

  }
  .gradient-bottom {
    background: rgb(0,0,0);
    background: linear-gradient(135deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
  }
}







.magic {
  color: transparent;
  background: linear-gradient(234deg, rgba(237,30,121,1) 0%, rgba(197,15,133,1) 25%, rgba(158,0,144,1) 50%, rgba(197,15,133,1) 75%, rgba(237,30,121,1) 100%);
  -webkit-background-clip: text;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  background-size: 200%;
  animation: background-pan 3s linear infinite;
}

@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}
</style>