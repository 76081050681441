<template>
  <div class="w-full flex flex-col place-content-center place-items-center pt-10 px-6 2xl:px-52 pb-10 font-montserrat ">
    <div class="background p-8 rounded-xl">
      <h1 class="text-gray-200 text-3xl w-full flex place-items-center place-content-start font-semibold">Beachte bitte</h1>
      <p class="text-gray-300 text-lg pt-4 pl-4">Da wir ein Projekt sind, das aus Leuten besteht, die es als Hobby machen, bleibt wenig Geld für eine Bezahlung oder dauerhafte Vergütung. Wir sind nicht daran interessiert, mit dem Projekt Gewinn zu erwirtschaften. Wir möchten das Projekt und die Teammitglieder schützen. Das Ganze funktioniert nur mit einer Verschwiegenheitserklärung - NDA.</p>
    </div>

  </div>
</template>

<script>
export default {
  name: "jobDisclaimer"
}
</script>

<style scoped>

.background {
  /* From https://css.glass */
  /*background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);*/
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.7rem);
  -webkit-backdrop-filter: blur(12.5px);
}

</style>