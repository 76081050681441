<template>
  <div id="landingPageContainer" class="h-screen w-screen flex place-items-end place-content-start relative">

<!--      <video class="h-full w-full object-cover absolute" playsinline autoplay muted loop :poster="require('@/assets/img/hero_poster.jpg')" id="bgvid">-->
<!--&lt;!&ndash;        <source :src="require('@/assets/img/hero_1.mp4')" type="video/mp4">&ndash;&gt;-->
<!--      </video>-->

<!--      <img :src="require('@/assets/img/lumina-v.svg')" class="h-3/4 absolute z-10 right-1/8 rotate-3 drop-shadow-2xl" alt="">-->
    <div id="landingPageVideo">
      <div id="landingPage" class="h-full w-full absolute flex flex-col place-content-center place-items-center">
        <img :src="require('@/assets/img/lumina_roleplay.svg')" class="2xl:w-2/5 w-3/4" alt="">
<!--        <h1 id="headline" class="text-gray-200 text-5xl">Lust auf realistisches Roleplay?</h1>-->
      </div>
    </div>




  </div>

</template>

<script>

// import TypeIt from "typeit";

// new TypeIt('#type-effect', {
//   deleteSpeed: 50,
//   loop: true
// })
//     .pause(2000)
//     .delete()
//     .pause(500)
//     .type("eine neue Welt?")
//     .pause(2000)
//     .delete()
//     .pause(500)
//     .type("einzigartige Konzepte?")
//     .pause(2000)
//     .delete()
//     .pause(500)
//     .type("Chancengleichheit?")
//     .pause(2000)
//     .delete()
//     .pause(500)
//     .type("dein eigenes Leben?")
//     .pause(2000)
//     .delete()
//     .go();

export default {
  name: "landingPage",
  computed: {

  }
}
</script>

<style scoped>

#landingPageVideo {
  background: url("@/assets/img/hero_elegy_compressed.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(90%);
}


#landingPageContainer video,#landingPageContainer div{
  /*background: url("@/assets/img/hero.png");*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*width: calc(100% - 4rem);*/
  /*height: calc(100% - 6rem);*/
  height: 100%;
  width: 100%;

  /*border-radius: 0 2rem 2rem 0;*/
}

/*video {*/
/*  border-radius: inherit;*/
/*}*/

/*#landingPage:before{*/
/*  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);*/
/*  filter: blur(10px);*/
/*  background: inherit;*/
/*}*/

#landingPage {
  /* From https://css.glass */
  background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);
  /*background: rgba(108, 108, 111, 0.15);*/
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5rem);
  /*-webkit-backdrop-filter: blur(5px);*/
  border-radius: inherit;
}

#landingPageContainer {
  background: rgb(0,0,0);
  /*background: linear-gradient(-45deg, rgba(197, 15, 133, 0.4) 0%, transparent 100%);*/
  background: linear-gradient(135deg, rgba(102, 45, 145, 0.2) 0%, transparent 70%);

}

</style>