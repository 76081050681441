<template>
  <div id="home" class="w-screen h-full select-none overflow-x-hidden bg-gray-900 relative">
    <nav-bar></nav-bar>
    <landing-page></landing-page>
    <core-features></core-features>
    <road-map></road-map>
    <footer-section></footer-section>
  </div>
</template>

<script>
import landingPage from "@/components/sections/landingPage";
import coreFeatures from "@/components/sections/coreFeatures";
import roadMap from "@/components/sections/roadMap";
import footerSection from "@/components/sections/footerSection";
import navBar from "@/components/sections/navBar";

export default {
  name: 'HomeView',
  components: {
    landingPage,
    coreFeatures,
    roadMap,
    footerSection,
    navBar,
  }
}
</script>