<template>
  <div id="base" class="w-screen h-screen select-none bg-gray-900">
<!--      <nav class="absolute top-0 h-16 z-50 bg-blue-500 flex place-content-end place-items-center w-full pr-8 text-white text-xl">-->
<!--        <router-link to="/">Home</router-link> |-->
<!--        <router-link to="/about">About</router-link>-->
<!--      </nav>-->
    <router-view/> <!-- Put navbar in router view? -->
  </div>


</template>

<script>
import { inject } from 'vue';

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Default Title',
    // all titles will be injected into this template
    titleTemplate: '%s | My Awesome Webapp'
  },
  setup() {
    const plausible = inject('plausible');
    return {
      plausible,
    };
  },
  created() {
    const html = document.documentElement; // returns the html tag
    html.setAttribute('lang', 'de');
  },
  mounted() {
    // let plausibleScript = document.createElement('script')
    // plausibleScript.setAttribute('src', 'https://plausible.io/js/script.js')
    // plausibleScript.setAttribute('data-domain', 'luminarp.de')
    // plausibleScript.defer = true
    // document.head.appendChild(plausibleScript)
  }
}

</script>

<style>
#app {
  font-family: 'Ubuntu', sans-serif;
  /*text-align: center;*/
  /*color: #2c3e50;*/
}

/*nav a {*/
/*  filter: drop-shadow(0px 0px 15px #000);*/
/*}*/


nav a.highlight.router-link-exact-active {
  color: #C50F85;
  font-weight: bold;
}

/*nav a.router-link-exact-active:after {*/
/*  transform: scaleX(1);*/
/*  transform-origin: bottom left;*/
/*}*/


nav a {
  display: inline-block;
  position: relative;
}

nav a.highlight:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.2rem;
  bottom: -0.1rem;
  left: 0;
  background-color: #C50F85;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  border-radius: 1rem;
}

nav a.highlight:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}



/*@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/

.font-ubuntu {
  font-family: 'Ubuntu', sans-serif;
}

.font-montserrat{
  font-family: 'Montserrat', sans-serif;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 0.75rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b7280;
  border-radius: 0.3rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e5e7eb;
}
</style>
