<template>
  <div class="w-full flex flex-col pt-32 pb-24 px-6 2xl:px-52">
    <h1 class="text-gray-200 text-4xl 2xl:text-6xl w-full flex place-items-center place-content-start font-semibold">Wir suchen dich!</h1>
    <p class="text-gray-300 text-2xl pt-8">Unser Team ist bisher noch sehr klein und um unsere Ziele zu erreichen, brauchen wir tatkräftige Unterstützung! Wenn dir unsere Ideen, Vorstellungen und Konzepte gefallen und wir dein Interesse geweckt haben, schreib <span class="font-semibold select-text whitespace-nowrap">Mary | RockAngelx3#5105</span> auf Discord!</p>
    <h1 class="text-gray-200 text-3xl w-full flex place-items-center place-content-start font-semibold pt-12 pb-4">Das bieten wir dir</h1>
    <div class="w-full flex flex-wrap gap-6">
      <div v-for="(offer, index) in offers" class="w-full 2xl:w-fit">
        <div class="w-full h-full flex place-items-center p-6 background rounded-xl">
          <h2 class="w-full text-center text-2xl font-medium text-gray-200">{{ offer }}</h2>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "whatWeOffer",
  data() {
    return {
      offers: [
        'Einen festen Platz im Team',
        'Die Möglichkeit, Ideen und Konzepte einzubringen',
        'Ein freundliches und aufgeschlossenes Team',
        'Keinen Zeitdruck oder Stress',
        'Rücksicht auf Berufstätige, Eltern und Leute mit wenig Zeit',
        'Hilfsbereitschaft',
        'Flache Hierarchie'
      ],
    }
  }
}
</script>

<style scoped>

.background {
  /* From https://css.glass */
  /*background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);*/
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.7rem);
  -webkit-backdrop-filter: blur(12.5px);
}

</style>