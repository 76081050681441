<template>
<div class="feature-page w-full h-full rounded-2xl grid grid-cols-3 bg-green-300">
  <img src="@/assets/ui/close.svg" alt="" @click="close" class="absolute top-4 right-4 w-8 z-20 hover:cursor-pointer hover:scale-102 transition duration-200">
  <div class="feature-page-background w-full h-full col-span-2 rounded-tl-2xl rounded-bl-2xl" v-bind:style="{ 'backgroundImage': 'url(' + backgroundUrl + ')' }"></div>
  <div class="w-full h-full rounded-tr-2xl rounded-br-2xl flex flex-col px-10 pb-8 pt-8 gap-6">
    <h1 class="text-3xl font-medium text-gray-200"><slot name="title"></slot></h1>
    <slot name="text"></slot>
<!--    <p class="text-xl text-gray-300"><slot name="text"></slot></p>-->
  </div>
</div>
</template>

<script>
export default {
  name: "featurePage",
  props: {
    image: String,
  },
  emits:{
    exit: null,
  },
  data() {
    return {
    };
  },
  computed: {
    backgroundUrl(){
      return require('../assets/img/'+this.image);
    }
  },
  methods: {
    close(){
      this.$emit("exit");
    }
  }
}
</script>

<style scoped>

.feature-page-background{
  /*background: url("@/assets/img/core-features/nightlife.jpg");*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(100%);
  opacity: 65%;
}

.feature-page {
  /* From https://css.glass */
  /*background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);*/
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.7rem);
  -webkit-backdrop-filter: blur(12.5px);
}

</style>