<template>
  <div class="w-full text-gray-200 text-lg pt-48 pb-16 px-6 2xl:px-52">
    <h1 class="text-4xl text-white pb-16">Impressum</h1>
    <p>Angaben gemäß § 5 TMG</p>
    <p>Christopher Monje <br>
      c/o Postflex #3104<br>
      Emsdettener Str. 10<br>
      48268 Greven<br>
      Keine Pakete oder Päckchen - Annahme wird verweigert!<br>
    </p>
    <p class="pt-4"> <strong>Vertretungsberechtigte Personen: </strong><br>
      Maria Figura<br>
    </p>
    <p class="pt-4"><strong>Kontakt:</strong> <br>
      E-Mail: <a href='mailto:christopher.monje@luminarp.de'>kontakt@luminarp.de</a><br></p>

    <p class="pt-4"><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br>
      Christopher Monje <br>
      c/o Postflex #3104<br>
      Emsdettener Str. 10<br>
      48268 Greven<br>
      Keine Pakete oder Päckchen - Annahme wird verweigert!<br>
    </p>
    <p class="pt-12"><strong class="text-xl">Haftungsausschluss: </strong><br><br><strong>Haftung für Links</strong><br>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
      können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
      stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
      Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
      Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
      Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
      derartige Links umgehend entfernen.<br><br><strong>Urheberrecht</strong><br>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
      Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
      Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
      dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
      Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
      werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
      derartige Inhalte umgehend entfernen.<br>
    </p><br>
  </div>
</template>

<script>
export default {
  name: "impressumSection"
}
</script>

<style scoped>

</style>