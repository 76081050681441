<template>
  <div id="jobSection" class="font-montserrat w-full h-fit 2xl:h-full flex flex-col place-content-center place-items-center pt-10 px-6 2xl:px-52 pb-20">
<!--    <h1 class="text-gray-200 text-6xl w-full h-48 flex place-items-center place-content-start font-semibold">Wir suchen dich<span class="italic">!</span></h1>-->
    <h1 class="text-gray-200 text-4xl 2xl:text-6xl w-full 2xl:h-48 pb-6 2xl:pb-0 flex place-items-center place-content-start font-semibold">Offene Positionen</h1>
    <div id="jobCardContainer" class="hidden w-full h-full 2xl:grid grid-cols-2 grid-rows-1 gap-12 place-content-center place-items-center ">
      <feature-card v-show="cards.show" @click="highlightFeature('frontend')" :image="this.jobs.frontend.image">Frontend Entwickler</feature-card>
      <feature-card v-show="cards.show" @click="highlightFeature('backend')" :image="this.jobs.backend.image">Backend Entwickler</feature-card>
<!--      <feature-card v-show="cards.show" @click="highlightFeature('business')" :image="this.features.business.image">Unternehmen</feature-card>-->
<!--      <feature-card v-show="cards.show" @click="highlightFeature('crafting')" :image="this.features.crafting.image">Crafting und Kochen</feature-card>-->
<!--      <feature-card v-show="cards.show" @click="highlightFeature('nightlife')" :image="this.features.nightlife.image">Nachtleben</feature-card>-->
<!--      <feature-card v-show="cards.show" @click="highlightFeature('activities')" :image="this.features.activities.image">Freizeitaktivitäten</feature-card>-->


      <feature-page v-show="page.show" :image="this.page.image" @exit="showJobPage(false)" class="col-span-3 row-span-2">
        <template v-slot:title>{{this.page.title}}</template>
        <template v-slot:text>
          <div>
            <h1 class="text-xl font-medium text-gray-200">Deine Skills</h1>
            <ul class="text-gray-300 text-lg list-disc pl-8">
              <li v-for="skill in this.page.skills">{{ skill }}</li>
            </ul>
          </div>

          <div class="w-full">
            <h1 class="text-xl font-medium text-gray-200">Deine Person</h1>
            <ul class="text-gray-300 text-lg list-disc pl-8">
              <li>Mindestens 18 Jahre alt</li>
              <li>Motivation und gute Laune</li>
              <li>Teamfähig</li>
              <li>Interesse am Projekt</li>
              <li>Aktivität und Erreichbarkeit</li>
            </ul>
          </div>

          <div>
            <h1 class="text-xl font-medium text-gray-200">Deine Aufgaben</h1>
            <ul class="text-gray-300 text-lg list-disc pl-8">
              <li v-for="task in this.page.tasks">{{ task }}</li>
            </ul>
          </div>

        </template>
      </feature-page>
    </div>

    <div class="flex 2xl:hidden w-full ">
      <div class="accordion flex flex-col gap-4 w-full" id="accordionExample">

        <div v-for="(job, index) in jobs" class="accordion-item bg-glass">
          <h2 class="accordion-header mb-0" :id="'header' + index">
            <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
              {{ job.title }}
            </button>
          </h2>
          <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'header' + index">
            <div class="accordion-body py-4 px-5 text-lg text-gray-200 flex flex-col gap-6">
              <div>
                <h1 class="text-xl font-medium text-gray-200">Deine Skills</h1>
                <ul class="text-gray-300 text-lg list-disc pl-8">
                  <li v-for="skill in job.skills">{{ skill }}</li>
                </ul>
              </div>

              <div class="w-full">
                <h1 class="text-xl font-medium text-gray-200">Deine Person</h1>
                <ul class="text-gray-300 text-lg list-disc pl-8">
                  <li>Mindestens 18 Jahre alt</li>
                  <li>Motivation und gute Laune</li>
                  <li>Teamfähig</li>
                  <li>Interesse am Projekt</li>
                  <li>Aktivität und Erreichbarkeit</li>
                </ul>
              </div>

              <div>
                <h1 class="text-xl font-medium text-gray-200">Deine Aufgaben</h1>
                <ul class="text-gray-300 text-lg list-disc pl-8">
                  <li v-for="task in job.tasks">{{ task }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import featureCard from "@/components/featureCard";
import featurePage from "@/components/featurePage";
export default {
  name: "jobSection",
  components: {
    featureCard,
    featurePage
  },
  data(){
    return {
      jobs: {
        frontend: {
          image: "jobs/frontend_compressed.jpg",
          title: 'Frontend Entwickler',
          skills: ['JavaScript', 'HTML, CSS, VueJS', 'Erfahrung mit Alt:V','Erfahrung im Roleplay von Vorteil'],
          tasks: ['Du konvertierst die Designs in Code','Du verbindest das Backend mit der UI'],
        },
        backend: {
          image: "jobs/backend_compressed.jpg",
          title: 'Backend Entwickler',
          skills: ['MySQL, EFCore', 'C#, Objektorientiertes Programmieren', 'Erfahrung mit Alt:V von Vorteil','Erfahrung im Roleplay von Vorteil'],
          tasks: ['Neue Features implementieren', 'Datenbank verwalten'],
        },
      },
      cards: {
        show: true
      },
      page: {
        show: false,
        image: "jobs/frontend_compressed.jpg",
        title: '',
        text: '',
        skills: '',
        tasks: '',
      }
    };
  },
  methods: {
    highlightFeature(type)
    {
      switch (type) {
        case 'frontend':
          this.page.image = this.jobs.frontend.image;
          this.page.title = this.jobs.frontend.title;
          this.page.text = this.jobs.frontend.text;
          this.page.skills = this.jobs.frontend.skills;
          this.page.tasks = this.jobs.frontend.tasks;
          break;
        case 'backend':
          this.page.image = this.jobs.backend.image;
          this.page.title = this.jobs.backend.title;
          this.page.text = this.jobs.backend.text;
          this.page.skills = this.jobs.backend.skills;
          this.page.tasks = this.jobs.backend.tasks;
          break;
      }

      this.showJobPage(true);
    },
    showJobPage(state)
    {
      this.page.show = state;
      this.cards.show = !state;
    }
  }
}
</script>

<style scoped>

#coreFeatures {
  background: rgb(0,0,0);
  background: linear-gradient(45deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
}

.magic {
  color: transparent;
  background: linear-gradient(234deg, rgba(237,30,121,1) 0%, rgba(197,15,133,1) 25%, rgba(158,0,144,1) 50%, rgba(197,15,133,1) 75%, rgba(237,30,121,1) 100%);
  -webkit-background-clip: text;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  background-size: 200%;
  animation: background-pan 3s linear infinite;
}

@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background: linear-gradient(to bottom right, #662d91, #821690);
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(200);
}

.accordion-button::after {
  filter: brightness(200);
}

.bg-glass {
  /* From https://css.glass */
  background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5rem);
  /*-webkit-backdrop-filter: blur(5px);*/
}


</style>