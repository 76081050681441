<template>
  <div class="w-screen h-screen select-none overflow-x-hidden bg-gray-900 relative">
    <nav-bar></nav-bar>
    <div class="w-full h-full text-gray-200 text-lg pt-16 pb-16 px-16 flex flex-col gap-16 place-items-center place-content-center">
      <img :src="require('@/assets/img/lumina_roleplay.svg')" alt="Lumina RP Logo" class="w-1/2 h-1/2">
      <h1 class="magic h-32 text-4xl">COMING SOON</h1>
    </div>
    <footer-section></footer-section>
  </div>
</template>

<script>
import navBar from "@/components/sections/navBar";
import footerSection from "@/components/sections/footerSection";


export default {
  name: 'ComingSoonView',
  components: {
    navBar,
    footerSection
  }
}
</script>

<style scoped>

.magic {
  color: transparent;
  background: linear-gradient(234deg, rgba(237,30,121,1) 0%, rgba(197,15,133,1) 25%, rgba(158,0,144,1) 50%, rgba(197,15,133,1) 75%, rgba(237,30,121,1) 100%);
  -webkit-background-clip: text;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  background-size: 200%;
  animation: background-pan 3s linear infinite;
}

@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}


</style>