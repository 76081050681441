<template>
  <Disclosure as="nav" class="absolute top-0 z-50 w-full" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 lg:pt-2 relative text-gray-200">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <router-link to="/">
              <img class="block h-10 w-auto lg:hidden drop-shadow-lg" :src="require('@/assets/img/lumina_v_color_without_decoration.svg')" alt="Lumina" />
              <img class="hidden h-12 w-auto lg:block" :src="require('@/assets/img/lumina_v_color_without_decoration.svg')" alt="Lumina" />
            </router-link>

          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-8">
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-200 highlight', 'my-2 rounded-md text-xl font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
            </div>
          </div>
        </div>
<!--        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">-->
<!--          <button type="button" class="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">-->
<!--            <span class="sr-only">View notifications</span>-->
<!--            <BellIcon class="h-6 w-6" aria-hidden="true" />-->
<!--          </button>-->

<!--          &lt;!&ndash; Profile dropdown &ndash;&gt;-->
<!--          <Menu as="div" class="relative ml-3">-->
<!--            <div>-->
<!--              <MenuButton class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">-->
<!--                <span class="sr-only">Open user menu</span>-->
<!--                <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />-->
<!--              </MenuButton>-->
<!--            </div>-->
<!--            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">-->
<!--              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">-->
<!--                <MenuItem v-slot="{ active }">-->
<!--                  <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Your Profile</a>-->
<!--                </MenuItem>-->
<!--                <MenuItem v-slot="{ active }">-->
<!--                  <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Settings</a>-->
<!--                </MenuItem>-->
<!--                <MenuItem v-slot="{ active }">-->
<!--                  <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Sign out</a>-->
<!--                </MenuItem>-->
<!--              </MenuItems>-->
<!--            </transition>-->
<!--          </Menu>-->
<!--        </div>-->
      </div>
    </div>

    <DisclosurePanel class="sm:hidden p-2">
      <div class="space-y-1 px-2 pt-2 pb-3 bg-glass rounded-lg">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-200', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon, HeartIcon } from '@heroicons/vue/24/outline'

const navigation = [
  // { name: 'Home', href: '#/', current: true },
  { name: 'Konzept', href: '#/concept', current: false },
  { name: 'Wir suchen dich!', href: '#/voluntary', current: false },
  { name: 'FAQ', href: '#/faq', current: false },
  { name: 'Discord', href: 'https://discord.gg/mjjDc6ZrqM', current: false },
]
</script>

<style scoped>

.bg-glass {
  /* From https://css.glass */
  background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5rem);
  /*-webkit-backdrop-filter: blur(5px);*/
}

a {
  display: block;
  position: relative;
}

a.highlight:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.2rem;
  bottom: -0.1rem;
  left: 0;
  background-color: #C50F85;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  border-radius: 1rem;
}

a.highlight:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

</style>