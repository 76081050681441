<template>
<div class="w-full text-gray-200 text-lg pt-48 pb-16 px-6 2xl:px-52">
  <h1 class="2xl:hidden text-4xl text-white pb-16">Datenschutz-erklärung</h1>
  <h1 class="hidden 2xl:block text-4xl text-white pb-16">Datenschutzerklärung</h1>


  <h2 id="m14" class="text-2xl pb-4">Einleitung</h2>
  <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten
    (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die
    Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen
    der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie
    innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet
    als "Onlineangebot“).</p>
  <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>

  <p class="pt-2">Stand: 10. November 2022</p>
  <h2 class="text-2xl pb-4 pt-8">Inhaltsübersicht</h2>
<!--  <ul class="index list-decimal pl-8">-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m14">Einleitung</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m3">Verantwortlicher</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#mOverview">Übersicht der Verarbeitungen</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m13">Maßgebliche Rechtsgrundlagen</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m27">Sicherheitsmaßnahmen</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m25">Übermittlung von personenbezogenen Daten</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m24">Datenverarbeitung in Drittländern</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m12">Löschung von Daten</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m134">Einsatz von Cookies</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m225">Bereitstellung des Onlineangebotes und Webhosting</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m182">Kontakt- und Anfragenverwaltung</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m408">Umfragen und Befragungen</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m263">Webanalyse, Monitoring und Optimierung</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m136">Präsenzen in sozialen Netzwerken (Social Media)</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m328">Plugins und eingebettete Funktionen sowie Inhalte</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m15">Änderung und Aktualisierung der Datenschutzerklärung</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m10">Rechte der betroffenen Personen</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="index-link" href="#/datenschutz/#m42">Begriffsdefinitionen</a>-->
<!--    </li>-->
<!--  </ul>-->
  <ul class="index list-decimal pl-8">
    <li>
      <p class="index-link">Einleitung</p>
    </li>
    <li>
      <p class="index-link">Verantwortlicher</p>
    </li>
    <li>
      <p class="index-link">Übersicht der Verarbeitungen</p>
    </li>
    <li>
      <p class="index-link">Maßgebliche Rechtsgrundlagen</p>
    </li>
    <li>
      <p class="index-link">Sicherheitsmaßnahmen</p>
    </li>
    <li>
      <p class="index-link">Übermittlung von personenbezogenen Daten</p>
    </li>
    <li>
      <p class="index-link">Datenverarbeitung in Drittländern</p>
    </li>
    <li>
      <p class="index-link">Löschung von Daten</p>
    </li>
    <li>
      <p class="index-link">Einsatz von Cookies</p>
    </li>
    <li>
      <p class="index-link">Bereitstellung des Onlineangebotes und Webhosting</p>
    </li>
    <li>
      <p class="index-link">Kontakt- und Anfragenverwaltung</p>
    </li>
    <li>
      <p class="index-link">Umfragen und Befragungen</p>
    </li>
    <li>
      <p class="index-link">Webanalyse, Monitoring und Optimierung</p>
    </li>
    <li>
      <p class="index-link">Präsenzen in sozialen Netzwerken (Social Media)</p>
    </li>
    <li>
      <p class="index-link">Plugins und eingebettete Funktionen sowie Inhalte</p>
    </li>
    <li>
      <p class="index-link">Änderung und Aktualisierung der Datenschutzerklärung</p>
    </li>
    <li>
      <p class="index-link">Rechte der betroffenen Personen</p>
    </li>
    <li>
      <p class="index-link">Begriffsdefinitionen</p>
    </li>
  </ul>
  <h2 id="m3" class="text-2xl pb-4 pt-8">Verantwortlicher</h2>
  <p class="pb-2">Christopher Monje<br>c/o Postflex #3104<br>Emsdettener Str. 10<br>48268 Greven<br>Keine Pakete oder Päckchen - Annahme wird verweigert!</p>
  Vertretungsberechtigte Personen: <p class="pb-2">Maria Figura</p>
  E-Mail-Adresse: <p class="pb-2"><a href="mailto:christopher.monje@luminarp.de">christopher.monje@luminarp.de</a></p>
  Impressum: <p class="pb-2"><router-link to="/impressum">https://luminarp.de/#/impressum</router-link></p>
  <h2 id="mOverview" class="text-2xl pb-4 pt-8">Übersicht der Verarbeitungen</h2>
  <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und
    verweist auf die betroffenen Personen.</p>
  <h3 class="text-xl pb-3 pt-6">Arten der verarbeiteten Daten</h3>
  <ul class="list-disc pl-8">
    <li>Bestandsdaten.</li>
    <li>Kontaktdaten.</li>
    <li>Inhaltsdaten.</li>
    <li>Nutzungsdaten.</li>
    <li>Meta-/Kommunikationsdaten.</li>
  </ul>
  <h3 class="text-xl pb-3 pt-6">Kategorien betroffener Personen</h3>
  <ul class="list-disc pl-8">
    <li>Kommunikationspartner.</li>
    <li>Nutzer.</li>
    <li>Teilnehmer.</li>
  </ul>
  <h3 class="text-xl pb-3 pt-6">Zwecke der Verarbeitung</h3>
  <ul class="list-disc pl-8">
    <li>Kontaktanfragen und Kommunikation.</li>
    <li>Sicherheitsmaßnahmen.</li>
    <li>Reichweitenmessung.</li>
    <li>Verwaltung und Beantwortung von Anfragen.</li>
    <li>Feedback.</li>
    <li>Marketing.</li>
    <li>Profile mit nutzerbezogenen Informationen.</li>
    <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
    <li>Informationstechnische Infrastruktur.</li>
  </ul>
  <h3 id="m13" class="text-xl pb-3 pt-6">Maßgebliche Rechtsgrundlagen</h3>
  <p>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten
    verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in
    Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
    maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.</p>
  <ul class="list-disc pl-8 py-2">
    <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)</strong> - Die
      Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur
      Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.</li>
    <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - Die Verarbeitung ist zur Wahrung
      der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen
      oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern,
      überwiegen.</li>
  </ul>
  <p>Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz
    in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
    Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf
    Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
    Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im
    Einzelfall einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des
    Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder Beendigung
    von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der
    einzelnen Bundesländer zur Anwendung gelangen.</p>
  <h2 id="m27" class="text-2xl pb-4 pt-8">Sicherheitsmaßnahmen</h2>
  <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
    Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
    unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher
    Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
    gewährleisten.</p>
  <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
    Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der
    Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
    eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung
    der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung
    bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
    Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>
  <p>TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
    TLS-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres
    Browsers.</p>
  <h2 id="m25" class="text-2xl pb-4 pt-8">Übermittlung von personenbezogenen Daten</h2>
  <p>Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen,
    Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
    offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit IT-Aufgaben beauftragte Dienstleister oder
    Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen Fall beachten wir
    die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz
    Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.</p>
  <p>Datenübermittlung innerhalb der Organisation: Wir können personenbezogene Daten an andere Stellen innerhalb unserer
    Organisation übermitteln oder ihnen den Zugriff auf diese Daten gewähren. Sofern diese Weitergabe zu administrativen
    Zwecken erfolgt, beruht die Weitergabe der Daten auf unseren berechtigten unternehmerischen und
    betriebswirtschaftlichen Interessen oder erfolgt, sofern sie Erfüllung unserer vertragsbezogenen Verpflichtungen
    erforderlich ist oder wenn eine Einwilligung der Betroffenen oder eine gesetzliche Erlaubnis vorliegt.</p>
  <h2 id="m24" class="text-2xl pb-4 pt-8">Datenverarbeitung in Drittländern</h2>
  <p>Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums
    (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung
    bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang
    mit den gesetzlichen Vorgaben. </p>
  <p>Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung verarbeiten
    oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen Verpflichtung
    durch sogenannte Standardschutzklauseln der EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher
    internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission: <a
        href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
        target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a>).
  </p>
  <h2 id="m12" class="text-2xl pb-4 pt-8">Löschung von Daten</h2>
  <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung
    erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung
    dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind). Sofern die Daten nicht gelöscht werden,
    weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke
    beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die
    aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung,
    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
    juristischen Person erforderlich ist. </p>
  <p>Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten beinhalten, die
    für die jeweiligen Verarbeitungen vorrangig gelten.</p>
  <h2 id="m134" class="text-2xl pb-4 pt-8">Einsatz von Cookies</h2>
  <p>Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten speichern und
    Informationen aus den Endgeräten auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen Warenkorbinhalt in
    einem E-Shop, die aufgerufenen Inhalte oder verwendete Funktionen eines Onlineangebotes speichern. Cookies können
    ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der Funktionsfähigkeit, Sicherheit und
    Komfort von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme. </p>
  <p><strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein.
    Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert
    ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern und das Auslesen der Informationen, also
    auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich gewünschten
    Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche Einwilligung wird gegenüber
    den Nutzern deutlich kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung.</p>
  <p><strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher datenschutzrechtlichen
    Rechtsgrundlage wir die personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir
    Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer
    Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage
    unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und
    Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen Pflichten
    erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu
    welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder
    im Rahmen von unseren Einwilligungs- und Verarbeitungsprozessen auf.</p>
  <p><strong>Speicherdauer&nbsp;</strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies
    unterschieden:</p>
  <ul class="list-disc pl-8 py-2">
    <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong>&nbsp;Temporäre Cookies werden spätestens
      gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile
      Applikation) geschlossen hat.</li>
    <li><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des Endgerätes
      gespeichert. So können beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt
      werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe von Cookies erhobenen Daten der
      Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern&nbsp;keine expliziten Angaben zur Art und
      Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer davon
      ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.</li>
  </ul>
  <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong>Nutzer können die von ihnen abgegebenen
    Einwilligungen jederzeit widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend&nbsp;den gesetzlichen
    Vorgaben im Art. 21 DSGVO einlegen.&nbsp;Nutzer können ihren Widerspruch auch über die Einstellungen ihres Browsers
    erklären, z.B. durch Deaktivierung der Verwendung von Cookies (wobei dadurch auch die Funktionalität unserer
    Online-Dienste eingeschränkt sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu
    Online-Marketing-Zwecken kann auch über die Websites <a href="https://optout.aboutads.info"
                                                            target="_blank">https://optout.aboutads.info</a> und <a href="https://www.youronlinechoices.com/"
                                                                                                                    target="_blank">https://www.youronlinechoices.com/</a> erklärt werden.</p>
  <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong>Wir setzen ein Verfahren zum
      Cookie-Einwilligungs-Management ein, in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies,
      bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und Anbieter
      eingeholt sowie von den Nutzern verwaltet und widerrufen werden können. Hierbei wird die Einwilligungserklärung
      gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und die Einwilligung entsprechend der
      gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig und/oder in einem Cookie
      (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung einem
      Nutzer, bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben zu den Anbietern von
      Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer der Speicherung der Einwilligung kann bis
      zu zwei Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt der
      Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche Kategorien von Cookies und/oder
      Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät gespeichert.</li>
  </ul>
  <h2 id="m225" class="text-2xl pb-4 pt-8">Bereitstellung des Onlineangebotes und Webhosting</h2>
  <p>Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck
    verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste
    an den Browser oder das Endgerät der Nutzer zu übermitteln.</p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
      Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
    <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
    <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit;
      Informationstechnische Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und technischen
      Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen.</li>
    <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
  </ul>
  <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Bereitstellung Onlineangebot auf gemietetem Speicherplatz: </strong>Für die Bereitstellung unseres
      Onlineangebotes nutzen wir Speicherplatz, Rechenkapazität und Software, die wir von einem entsprechenden
      Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig beziehen; <strong>Rechtsgrundlagen:</strong>
      Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
    <li><strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf unser Onlineangebot wird in Form von
      so genannten "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die Adresse und Name der abgerufenen
      Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf,
      Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
      Regelfall IP-Adressen und der anfragende Provider gehören.

      Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der
      Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
      anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen; <strong>Rechtsgrundlagen:</strong>
      Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Löschung von Daten:</strong>
      Logfile-Informationen werden für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
      anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen
      Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.</li>
    <li><strong>Content-Delivery-Network: </strong>Wir setzen ein "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein
      Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große Mediendateien, wie Grafiken oder
      Programm-Skripte, mit Hilfe regional verteilter und über das Internet verbundener Server schneller und sicherer
      ausgeliefert werden können; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
      f) DSGVO).</li>
    <li><strong>netcup: </strong>Leistungen auf dem Gebiet der Bereitstellung von informationstechnischer Infrastruktur
      und verbundenen Dienstleistungen (z.B. Speicherplatz und/oder Rechenkapazitäten);
      <strong>Dienstanbieter:</strong> netcup GmbH, Daimlerstraße 25, D-76185 Karlsruhe, Deutschland;
      <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
      <strong>Website:</strong> <a href="https://www.netcup.de/" target="_blank">https://www.netcup.de/</a>;
      <strong>Datenschutzerklärung:</strong> <a href="https://www.netcup.de/kontakt/datenschutzerklaerung.php"
                                                target="_blank">https://www.netcup.de/kontakt/datenschutzerklaerung.php</a>;
      <strong>Auftragsverarbeitungsvertrag:</strong> <a
          href="https://www.netcup-wiki.de/wiki/Zusatzvereinbarung_zur_Auftragsverarbeitung"
          target="_blank">https://www.netcup-wiki.de/wiki/Zusatzvereinbarung_zur_Auftragsverarbeitung</a>.</li>
  </ul>
  <h2 id="m182" class="text-2xl pb-4 pt-8">Kontakt- und Anfragenverwaltung</h2>
  <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen
    bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet soweit dies zur
    Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.</p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
      Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
      Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
    <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
    <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Verwaltung und Beantwortung von
      Anfragen; Feedback (z.B. Sammeln von Feedback via Online-Formular); Bereitstellung unseres Onlineangebotes und
      Nutzerfreundlichkeit.</li>
    <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); Vertragserfüllung
      und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).</li>
  </ul>
  <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular, E-Mail oder andere Kommunikationswege
      mit uns in Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des
      mitgeteilten Anliegens; <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art.
      6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
  </ul>
  <h2 id="m408" class="text-2xl pb-4 pt-8">Umfragen und Befragungen</h2>
  <p>Wir führen Umfragen und Befragungen durch, um Informationen für den jeweils kommunizierten Umfrage- bzw.
    Befragungszweck, zu sammeln. Die von uns durchgeführten Umfragen und Befragungen (nachfolgend "Befragungen") werden
    anonym ausgewertet. Eine Verarbeitung personenbezogener Daten erfolgt nur insoweit, als dies zu Bereitstellung und
    technischen Durchführung der Umfragen erforderlich ist (z.B. Verarbeitung der IP-Adresse, um die Umfrage im Browser
    des Nutzers darzustellen oder mithilfe eines Cookies eine Wiederaufnahme der Umfrage zu ermöglichen).</p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
      Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
      Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
    <li><strong>Betroffene Personen:</strong> Kommunikationspartner; Teilnehmer.</li>
    <li><strong>Zwecke der Verarbeitung:</strong> Feedback (z.B. Sammeln von Feedback via Online-Formular).</li>
    <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
  </ul>
  <h2 id="m263" class="text-2xl pb-4 pt-8">Webanalyse, Monitoring und Optimierung</h2>
  <p>Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der Auswertung der Besucherströme unseres
    Onlineangebotes und kann Verhalten, Interessen oder demographische Informationen zu den Besuchern, wie z.B. das
    Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B. erkennen,
    zu welcher Zeit unser Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt werden oder zur
    Wiederverwendung einladen. Ebenso können wir nachvollziehen, welche Bereiche der Optimierung bedürfen. </p>
  <p>Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B. unterschiedliche Versionen unseres
    Onlineangebotes oder seiner Bestandteile zu testen und optimieren.</p>
  <p>Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile, d.h. zu einem Nutzungsvorgang
    zusammengefasste Daten angelegt und Informationen in einem Browser, bzw. in einem Endgerät gespeichert und aus
    diesem ausgelesen werden. Zu den erhobenen Angaben gehören insbesondere besuchte Webseiten und dort genutzte
    Elemente sowie technische Angaben, wie der verwendete Browser, das verwendete Computersystem sowie Angaben zu
    Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder gegenüber den Anbietern der von
    uns eingesetzten Dienste einverstanden erklärt haben, können auch Standortdaten verarbeitet werden.</p>
  <p>Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein IP-Masking-Verfahren (d.h.,
    Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von Webanalyse,
    A/B-Testings und Optimierung keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern
    Pseudonyme. D.h., wir als auch die Anbieter der eingesetzten Software kennen nicht die tatsächliche Identität der
    Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten Angaben.</p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
      Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
    <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
    <li><strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
      wiederkehrender Besucher); Profile mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).</li>
    <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
    <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
  </ul>
  <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Matomo (ohne Cookies): </strong>Bei Matomo handelt es sich um eine datenschutzfreundliche
      Webanalysesoftware, die ohne Cookies eingesetzt wird und bei der die Erkennnung wiederkehrender Nutzer mit Hilfe
      eines so genannten "digitalen Fingerabdrucks" erfolgt, der anonymisiert gespeichert und alle 24 Stunden geändert
      wird; Beim "digitalen Fingerabdruck" werden Nutzerbewegungen innerhalb unseres Onlineangebotes mit Hilfe von
      pseudonymisierten IP-Adressen in Kombination mit nutzerseitige Browsereinstellungen so erfasst, dass
      Rückschlüsse auf die Identität einzelner Nutzer nicht möglich sind. Die im Rahmen der Nutzung von Matomo
      erhobenen Daten der Nutzer werden nur von uns verarbeitet und nicht mit Dritten geteilt;
      <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
      <strong>Website:</strong> <a href="https://matomo.org/" target="_blank">https://matomo.org/</a>.</li>
  </ul>
  <h2 id="m136" class="text-2xl pb-4 pt-8">Präsenzen in sozialen Netzwerken (Social Media)</h2>
  <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten der Nutzer, um
    mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns anzubieten.</p>
  <p>Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet werden
    können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer
    erschwert werden könnte.</p>
  <p>Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und Werbezwecke
    verarbeitet. So können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer
    Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen
    innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen
    Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die
    Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den
    Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
    sind und bei diesen eingeloggt sind).</p>
  <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out)
    verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.</p>
  <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin, dass diese am
    effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff auf die Daten
    der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
    benötigen, dann können Sie sich an uns wenden.</p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
      Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
      Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
    <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
    <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Feedback (z.B. Sammeln von Feedback
      via Online-Formular); Marketing.</li>
    <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
  </ul>
  <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Instagram: </strong>Soziales Netzwerk; <strong>Dienstanbieter:</strong> Meta Platforms Irland Limited, 4
      Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland; <strong>Rechtsgrundlagen:</strong> Berechtigte
      Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong> <a href="https://www.instagram.com"
                                                                                  target="_blank">https://www.instagram.com</a>; <strong>Datenschutzerklärung:</strong> <a
          href="https://instagram.com/about/legal/privacy"
          target="_blank">https://instagram.com/about/legal/privacy</a>.</li>
    <li><strong>Twitter: </strong>Soziales Netzwerk; <strong>Dienstanbieter:</strong> Twitter International Company, One
      Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Irland, Mutterunternehmen: Twitter Inc., 1355 Market Street,
      Suite 900, San Francisco, CA 94103, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
      1 S. 1 lit. f) DSGVO); <strong>Datenschutzerklärung:</strong> <a href="https://twitter.com/privacy"
                                                                       target="_blank">https://twitter.com/privacy</a>, (Settings: <a href="https://twitter.com/personalization"
                                                                                                                                      target="_blank">https://twitter.com/personalization</a>).</li>
    <li><strong>YouTube: </strong>Soziales Netzwerk und Videoplattform; <strong>Dienstanbieter:</strong> Google Ireland
      Limited, Gordon House, Barrow Street, Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong> Berechtigte
      Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Datenschutzerklärung:</strong> <a
          href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>;
      <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> <a href="https://adssettings.google.com/authenticated"
                                                             target="_blank">https://adssettings.google.com/authenticated</a>.</li>
  </ul>
  <h2 id="m328" class="text-2xl pb-4 pt-8">Plugins und eingebettete Funktionen sowie Inhalte</h2>
  <p>Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer jeweiligen Anbieter
    (nachfolgend bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um Grafiken, Videos
    oder Stadtpläne handeln (nachfolgend einheitlich bezeichnet als "Inhalte”).</p>
  <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer verarbeiten, da
    sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
    Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren
    jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner
    sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
    Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten
    dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer
    gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu verweisenden
    Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch mit solchen
    Informationen aus anderen Quellen verbunden werden.</p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
      Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Bestandsdaten (z.B. Namen,
      Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
    <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
    <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
    <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
  </ul>
  <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
  <ul class="m-elements list-disc pl-8 py-2">
    <li><strong>Einbindung von Drittsoftware, Skripten oder Frameworks (z. B. jQuery): </strong>Wir binden in unser
      Onlineangebot Software ein, die wir von Servern anderer Anbieter abrufen (z.B. Funktions-Bibliotheken, die wir
      zwecks Darstellung oder Nutzerfreundlichkeit&nbsp;unseres Onlineangebotes verwenden). Hierbei erheben die jeweiligen
      Anbieter die IP-Adresse der Nutzer und können diese zu Zwecken der&nbsp;Übermittlung der Software an den Browser der
      Nutzer sowie zu Zwecken der Sicherheit, als auch zur Auswertung und Optimierung ihres Angebotes verarbeiten. -
      Wir binden in unser Onlineangebot Software ein, die wir von Servern anderer Anbieter abrufen (z.B.
      Funktions-Bibliotheken, die wir zwecks Darstellung oder Nutzerfreundlichkeit&nbsp;unseres Onlineangebotes verwenden).
      Hierbei erheben die jeweiligen Anbieter die IP-Adresse der Nutzer und können diese zu Zwecken der&nbsp;Übermittlung
      der Software an den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur Auswertung und Optimierung
      ihres Angebotes verarbeiten; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
      f) DSGVO).</li>
    <li><strong>Google Fonts (Bereitstellung auf eigenem Server): </strong>Schriftarten ("Google Fonts") zwecks einer
      nutzerfreundlichen Darstellung unseres Onlineangebotes; <strong>Dienstanbieter:</strong> Die Google Fonts werden
      auf unserem Server gehostet, es werden keine Daten an Google übermittelt; <strong>Rechtsgrundlagen:</strong>
      Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
    <li><strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von Schriften (und Symbolen) zum Zwecke einer
      technisch sicheren, wartungsfreien und effizienten Nutzung von Schriften und Symbolen im Hinblick auf Aktualität
      und Ladezeiten, deren einheitliche Darstellung und Berücksichtigung möglicher lizenzrechtlicher Beschränkungen.
      Dem Anbieter der Schriftarten wird die IP-Adresse des Nutzers mitgeteilt, damit die Schriftarten im Browser des
      Nutzers zur Verfügung gestellt werden können. Darüber hinaus werden technische Daten (Spracheinstellungen,
      Bildschirmauflösung, Betriebssystem, verwendete Hardware) übermittelt, die für die Bereitstellung der Schriften
      in Abhängigkeit von den verwendeten Geräten und der technischen Umgebung notwendig sind. Diese Daten können auf
      einem Server des Anbieters der Schriftarten in den USA verarbeitet werden; <strong>Dienstanbieter:</strong>
      Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong>
      Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong> <a
          href="https://fonts.google.com/" target="_blank">https://fonts.google.com/</a>;
      <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy"
                                                target="_blank">https://policies.google.com/privacy</a>.</li>
    <li><strong>Font Awesome (Bereitstellung auf eigenem Server): </strong>Darstellung von Schriftarten und Symbolen;
      <strong>Dienstanbieter:</strong> Die Font Awesome Icons werden auf unserem Server gehostet, es werden keine
      Daten an den Anbieter von Font Awesome übermittelt; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
      (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
    <li><strong>YouTube-Videos: </strong>Videoinhalte; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
      House, Barrow Street, Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
      S. 1 lit. f) DSGVO); <strong>Website:</strong> <a href="https://www.youtube.com"
                                                        target="_blank">https://www.youtube.com</a>; <strong>Datenschutzerklärung:</strong> <a
          href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>;
      <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin: <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>, Einstellungen für die Darstellung von
      Werbeeinblendungen: <a href="https://adssettings.google.com/authenticated"
                             target="_blank">https://adssettings.google.com/authenticated</a>.</li>
  </ul>
  <h2 id="m15" class="text-2xl pb-4 pt-8">Änderung und Aktualisierung der Datenschutzerklärung</h2>
  <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
    Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich
    machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung)
    oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
  <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen
    angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor
    Kontaktaufnahme zu prüfen.</p>
  <h2 id="m10">Rechte der betroffenen Personen</h2>
  <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO
    ergeben:</p>
  <ul class="list-disc pl-8 py-2">
    <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
      jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs.
      1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
      gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
      betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
      personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
      es mit solcher Direktwerbung in Verbindung steht.</strong></li>
    <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit zu
      widerrufen.</li>
    <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende
      Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten
      entsprechend den gesetzlichen Vorgaben.</li>
    <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die
      Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu
      verlangen.</li>
    <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der gesetzlichen
      Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
      Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.</li>
    <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns
      bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und
      maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.</li>
    <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen
      verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
      insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
      mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen
      Daten gegen die Vorgaben der DSGVO verstößt.</li>
  </ul>
  <h2 id="m42" class="text-2xl pb-4 pt-8">Begriffsdefinitionen</h2>
  <p>In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten
    Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
    gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis
    dienen. Die Begriffe sind alphabetisch sortiert.</p>
  <ul class="glossary list-disc pl-8 py-2">
    <li><strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen, die sich auf eine
      identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene Person“) beziehen; als
      identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
      zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie)
      oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen,
      physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
      natürlichen Person sind. </li>
    <li><strong>Profile mit nutzerbezogenen Informationen:</strong> Die Verarbeitung von "Profilen mit nutzerbezogenen
      Informationen", bzw. kurz "Profilen" umfasst jede Art der automatisierten Verarbeitung personenbezogener Daten,
      die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die
      sich auf eine natürliche Person beziehen (je nach Art der Profilbildung können dazu unterschiedliche
      Informationen betreffend die Demographie, Verhalten und Interessen, wie z.B. die Interaktion mit Webseiten und
      deren Inhalten, etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die Interessen an bestimmten
      Inhalten oder Produkten, das Klickverhalten auf einer Webseite oder den Aufenthaltsort). Zu Zwecken des
      Profilings werden häufig Cookies und Web-Beacons eingesetzt. </li>
    <li><strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web Analytics bezeichnet) dient der
      Auswertung der Besucherströme eines Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
      bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können
      Webseiteninhaber z.B. erkennen, zu welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie sich
      interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an die Bedürfnisse ihrer Besucher
      anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons eingesetzt, um
      wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.
    </li>
    <li><strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder juristische Person, Behörde,
      Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
      Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet. </li>
    <li><strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter Verfahren
      ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff
      reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern, das
      Übermitteln oder das Löschen. </li>
  </ul>
  <p class="seal pt-8 text-gray-300 w-full flex place-items-center place-content-center"><a href="https://datenschutz-generator.de/"
                     title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank"
                     rel="noopener noreferrer nofollow">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a>
  </p>
</div>
</template>

<script>
export default {
  name: "privacyNotice"
}
</script>

<style scoped>

a {
  text-decoration: underline #C50F85;
}

a:hover {
  text-decoration: none;
  color: #C50F85;
}

</style>