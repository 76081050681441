<template>
  <div class="w-screen h-screen select-none overflow-x-hidden bg-gray-900 relative">
    <nav-bar></nav-bar>
    <privacy-notice></privacy-notice>
    <footer-section></footer-section>
  </div>
</template>

<script>
import navBar from "@/components/sections/navBar";
import footerSection from "@/components/sections/footerSection";
import privacyNotice from "@/components/sections/privacyNotice";

export default {
  name: 'PrivacyView',
  components: {
    navBar,
    footerSection,
    privacyNotice
  }
}
</script>