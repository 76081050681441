<template>
  <div id="footerContainer" class="w-full h-fit 2xl:h-48 flex place-content-center place-items-center relative overflow-hidden 2xl:px-16 mt-16">
    <div id="footer" class=" w-full h-full bg-gradient-to-br from-lumina-dark-purple to-lumina-medium-purple flex flex-col place-items-center place-content-end p-6 pt-10 2xl:px-16 2xl:pt-16">
      <div class="hidden 2xl:flex w-full h-full flex-col 2xl:flex-row 2xl:place-content-between 2xl:place-items-start">
        <ul class="h-16 flex gap-x-8 gap-y-4 2xl:gap-8  flex-wrap text-white text-1.5xl 2xl:text-xl place-items-center place-content-center">
          <li>
            <a href="https://status.luminarp.de" target="_blank" class="highlight">Serverstatus</a>
          </li>
<!--          <li>-->
<!--            <router-link to="/mod-credits" class="highlight">Mod Credits</router-link>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a href="https://discord.gg/MJVrycUBna" target="_blank" class="highlight">Kontakt</a>-->
<!--          </li>-->
          <li>
            <router-link to="/impressum" class="highlight">Impressum</router-link>
          </li>
          <li>
            <router-link to="/privacy" class="highlight">Datenschutz</router-link>
          </li>
        </ul>
        <div class="h-16 flex flex-col gap-2 place-content-center place-items-center">
          <h1 class="text-white font-semibold text-3xl "><a href="https://youtu.be/dQw4w9WgXcQ" target="_blank"><span class="magic transition transform duration-200">LUMINA</span></a> ROLEPLAY</h1>
          <div class="w-full h-full flex place-items-center place-content-center gap-8 text-white text-3xl">
            <a href="https://discord.gg/g62Hcb34e8" target="_blank" class="transform hover:scale-[1.3] transition duration-200"><font-awesome-icon icon="fa-brands fa-discord" class="" /></a>
            <a href="https://youtube.com/@luminaroleplay" target="_blank" class=""><font-awesome-icon icon="fa-brands fa-youtube" title="Youtube" class="transform hover:scale-[1.3] transition duration-200" /></a>
<!--            <a href="https://twitter.com" target="_blank" class=""><font-awesome-icon icon="fa-brands fa-twitter" class="transform hover:scale-[1.3] transition duration-200" /></a>-->

          </div>
        </div>
      </div>

      <div class="w-full h-fit flex flex-col gap-6 2xl:hidden place-items-center pb-4">
        <h1 class="text-white font-semibold text-3xl pb-4"><a href="https://youtu.be/dQw4w9WgXcQ" target="_blank"><span class="magic transition transform duration-200">LUMINA</span></a> ROLEPLAY</h1>
        <ul class="h-fit grid grid-cols-2 gap-x-8 gap-y-4 2xl:gap-8  flex-wrap text-white text-1.5xl 2xl:text-xl place-items-center place-content-center">
          <li>
            <a href="https://status.luminarp.de" target="_blank" class="highlight">Serverstatus</a>
          </li>
          <li>
            <router-link to="/impressum" class="highlight">Impressum</router-link>
          </li>
          <li>
            <router-link to="/privacy" class="highlight">Datenschutz</router-link>
          </li>
        </ul>
        <div class="h-16 flex flex-col gap-2 place-content-center place-items-center">

          <div class="w-full h-full flex place-items-center place-content-center gap-8 text-white text-3xl">
            <a href="https://discord.gg/mjjDc6ZrqM" target="_blank" class="transform hover:scale-[1.3] transition duration-200"><font-awesome-icon icon="fa-brands fa-discord" class="" /></a>
            <a href="https://youtube.com/@luminaroleplay" target="_blank" class=""><font-awesome-icon icon="fa-brands fa-youtube" title="Youtube" class="transform hover:scale-[1.3] transition duration-200" /></a>
<!--            <a href="https://twitter.com" target="_blank" class=""><font-awesome-icon icon="fa-brands fa-twitter" class="transform hover:scale-[1.3] transition duration-200" /></a>-->

          </div>
        </div>
      </div>

      <h1 class="text-gray-300 text-md">Copyright © {{ this.currentyear }} Lumina Roleplay</h1>
    </div>




  </div>
</template>

<script>
export default {
  name: "footerSection",
  computed: {
    currentyear() {
      return new Date().getFullYear();
    },
  }
}
</script>

<style scoped>


/*@import url('https://cdn-uicons.flaticon.com/uicons-brands/css/uicons-brands.css');*/

/*#footer {*/
/*  background: rgb(0,0,0);*/
/*  background: linear-gradient(-45deg, rgba(197, 15, 133, 0.4) 0%, transparent 100%);*/
/*  !*background: linear-gradient(135deg, rgba(102, 45, 145, 0.5) 0%, transparent 100%);*!*/

/*}*/

#footer{
  /*background: url("@/assets/img/hero.png");*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*width: calc(100% - 4rem);*/
  /*height: calc(100% - 5rem);*/
  border-radius: 2rem 2rem 0 0;
}

a.highlight {
  display: inline-block;
  position: relative;
}

a.highlight:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.2rem;
  bottom: -0.1rem;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  border-radius: 1rem;
}

a.highlight:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.magic:hover {
  color: transparent;
  background: linear-gradient(234deg, rgba(237,30,121,1) 0%, rgba(197,15,133,1) 25%, rgba(158,0,144,1) 50%, rgba(197,15,133,1) 75%, rgba(237,30,121,1) 100%);
  -webkit-background-clip: text;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  background-size: 200%;
  animation: background-pan 3s linear infinite;
}

@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}


</style>