<template>
  <div class="w-full pt-16 pb-4 px-52 flex place-content-center place-items-center">
    <h3 class="text-gray-200 text-2xl w-full text-center font-semibold">Wenn du dich in dem gesuchten Bereichen wiedersiehst und der Meinung bist, dass du zu uns passt, schreib <span class=" select-text whitespace-nowrap">Mary | RockAngelx3#5105</span> bei Discord!</h3>
  </div>
</template>

<script>
export default {
  name: "jobContact"
}
</script>

<style scoped>

</style>