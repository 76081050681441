<template>
<div @mouseover="setHover(true)" @mouseleave="setHover(false)"  class="feature-card cursor-pointer select-none w-full h-full rounded-2xl flex flex-col place-content-center place-items-center relative hover:scale-102 transition duration-200 border-2 border-transparent hover:border-gray-500 hover:drop-shadow-lg box-border">
  <div :class="{'feature-card-background-dim':!hover, 'feature-card-background-light':hover}" v-bind:style="{ 'backgroundImage': 'url(' + backgroundUrl + ')' }" class="feature-card-background w-full h-full"></div>
  <h1 :class="{'text-gray-200':!hover, 'text-gray-100':hover}" class="w-full h-full flex place-content-center place-items-center text-center text-3xl font-semibold absolute"><slot></slot></h1>
</div>
</template>

<script>
export default {
  name: "featureCard",
  props: {
    type: String,
    image: String
  },
  data() {
    return {

      hover: false,
    };
  },
  methods: {
    setHover(state)
    {
      this.hover = state;
    }
  },
  computed: {
    backgroundUrl(){
      return require('../assets/img/'+this.image);
    }
  }
}
</script>

<style scoped>

.feature-card-background{
  /*background: url("@/assets/img/core-features/nightlife.jpg");*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: inherit;
  border: inherit;
}

.feature-card-background-light{
  filter: brightness(100%);
  opacity: 75%;
}

.feature-card-background-dim{
  filter: brightness(50%);
  opacity: 50%;
}

.feature-card {
  /* From https://css.glass */
  /*background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);*/
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.7rem);
  -webkit-backdrop-filter: blur(12.5px);
}
</style>