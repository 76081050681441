<template>
  <section class="flex items-center h-full px-6 2xl:px-52 text-gray-200 font-montserrat gradient-top">
    <div class="container flex flex-col items-center justify-center mx-auto my-8">
      <div class="w-full text-center">
        <h2 class="mb-8 font-extrabold text-9xl dark:text-gray-600">
          <span class="sr-only">Error</span>404
        </h2>
        <p class="text-2xl font-semibold md:text-3xl mb-12">Sorry, diese Seite existiert nicht :(</p>
<!--        <p class="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p>-->
        <a rel="noopener noreferrer" href="#" class="px-8 py-3 font-semibold rounded-xl bg-gray-300 text-gray-800 hover:text-gray-200 transform hover:scale-110 transition-all duration-300 magic">Zurück zur Startseite</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "pageNotFound"
}
</script>

<style scoped>
.magic:hover {
  background-color: rgb(209 213 219);
  background: linear-gradient(234deg, rgba(237,30,121,1) 0%, rgba(197,15,133,1) 25%, rgba(158,0,144,1) 50%, rgba(197,15,133,1) 75%, rgba(237,30,121,1) 100%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  background-size: 200%;
  animation: background-pan 3s linear infinite;
}

.gradient-top {
  background: rgb(0,0,0);
  background: linear-gradient(225deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right;
}

.bg-glass {
  /* From https://css.glass */
  background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5rem);
  /*-webkit-backdrop-filter: blur(5px);*/
}

@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}
</style>