import {createApp, VueElement} from 'vue'
import VueMeta from "vue-meta";
import App from './App.vue'
import './index.css'
import router from './router'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import plausible from "@/plugins/plausible";

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faDiscord, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// add cat-space!

library.add(faDiscord, faYoutube, faTwitter , faCheck);

import * as twElements from 'tw-elements';
import * as flowbite from "flowbite";


createApp(App)
    .use(router, VueMeta, twElements, flowbite)
    .use(plausible, {
        domain: 'luminarp.de',
        hashMode: true,
        trackLocalhost: false,
        enableAutoOutboundTracking: true,
    })
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
