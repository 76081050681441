<template>
  <div v-if="position === 'center'" class="w-full flex place-content-center place-items-center font-montserrat">
    <h1 class="text-gray-200 text-2xl 2xl:text-6xl h-48 flex text-center font-semibold py-16"><slot name="text"></slot></h1>
  </div>
  <div v-if="position === 'left'" class="w-full flex place-content-center place-items-center pt-10 px-52 pb-20 font-montserrat">
    <h1 class="text-gray-200 text-6xl w-full h-48 flex place-items-center place-content-start font-semibold"><slot name="text"></slot></h1>
  </div>
</template>

<script>
export default {
  name: "inbetweenHeadline",
  props: {
    position: String,
  }
}
</script>

<style scoped>

</style>