<template>
  <div id="roadMap" class="hidden w-full h-fit 2xl:h-full 2xl:flex flex-col-reverse place-content-center place-items-center relative gap-8 gradient-top">

    <div class=" w-full h-32 flex flex-col place-content-center place-items-center relative">
      <div class="line w-320 h-2 bg-gray-700 rounded-tl-full rounded-bl-full absolute"></div>

      <!--    <h2 class="chapter-first bottom-52 w-48 text-center text-xl text-gray-300 absolute">Closed Alpha</h2>-->
      <div class="large-dot h-6 w-6 bg-lumina-medium-purple rounded-full absolute"></div>
      <h2 class="chapter chapter-second w-48 text-center text-xl text-gray-400 absolute">Open Alpha</h2>
      <div class="dot h-4 w-4 bg-gray-500 rounded-full absolute"></div>
      <h2 class="chapter w-48 text-center text-xl text-gray-400 absolute">Closed Beta</h2>
      <div class="dot h-4 w-4 bg-gray-500 rounded-full absolute"></div>
      <h2 class="chapter chapter-fourth w-48 text-center text-xl text-gray-400 absolute">Open Beta</h2>
      <div class="dot h-4 w-4 bg-gray-500 rounded-full absolute"></div>
      <h2 class="chapter chapter-fith w-48 text-center text-xl text-gray-400 absolute">Release</h2>
      <div class="dot h-4 w-4 bg-gray-500 rounded-full absolute"></div>
    </div>

    <div class="roadmap-card first w-380 h-160 bg-gray-700 rounded-2xl top-40 grid grid-cols-4 grid-rows-1 place-items-center px-12 pb-12 pt-28">
      <h1 class="text-4xl top-8 left-8 absolute text-white font-semibold">Closed Alpha <span class="text-2xl text-gray-400">Mitte 2023</span></h1>

<!--      <div class="w-full h-full bg-green-300"></div>-->
<!--      <div class="w-full h-full bg-yellow-400"></div>-->
<!--      <div class="w-full h-full bg-red-400"></div>-->
<!--      <div class="w-full h-full bg-violet-500"></div>-->
<!--      <div class="w-full h-full bg-teal-400"></div>-->
<!--      <div class="w-full h-full bg-blue-500"></div>-->


      <div class="w-full h-full flex flex-col gap-8 border-r-2 border-gray-500 px-6 pt-4">
        <h1 class="text-2xl text-white">Überleben des Spielers</h1>
        <ul class="list-disc pl-8 text-gray-300 flex flex-col text-xl gap-4">
          <li>
           Funktion von Essen und Trinken <!-- ✔-->
          </li>
          <li>
            Funktion von Atem und Ausdauer
          </li>
          <li>
            Funktion von Verlust von Lebenspunkten
          </li>
          <li>
            Inventar
          </li>
          <li>
            Lebensmittelshops
          </li>
          <li>
            Animationen für diverse Aktivitäten wie z.B. Essen
          </li>
          <li>
            Items und ihre Funktionen
          </li>
        </ul>
      </div>

      <div class="w-full h-full flex flex-col gap-8 border-l-2 border-r-2 border-gray-500 px-6 pt-4">
        <h1 class="text-2xl text-white">Wirtschaftssystem Teil 1</h1>
        <ul class="list-disc pl-8 text-gray-300 flex flex-col text-xl gap-4">
          <li>
            Bankautomaten
          </li>
          <li>
            Grundeinkommen
          </li>
          <li>
            Minijobs
          </li>
        </ul>
      </div>

      <div class="w-full h-full flex flex-col gap-8 border-l-2 border-r-2 border-gray-500 px-6 pt-4">
        <h1 class="text-2xl text-white">Fahrzeugsystem Teil 1</h1>
        <ul class="list-disc pl-8 text-gray-300 flex flex-col text-xl gap-4">
          <li>
            Fahrzeug Funktionen
          </li>
          <li>
            Gebrauchtwagenhändler
          </li>
          <li>
            Tanksystem
          </li>
          <li>
            Kaufbare Garagenplätze
          </li>
        </ul>
      </div>

      <div class="w-full h-full flex flex-col gap-8 border-l-2 border-gray-500 px-6 pt-4">
        <h1 class="text-2xl text-white">Sonstiges</h1>
        <ul class="list-disc pl-8 text-gray-300 flex flex-col text-xl gap-4">
          <li>
            Soundeffekte für diverse Features
          </li>
          <li>
            Motels und Hotels
          </li>
          <li>
            Spieler Interaktionsmenü
          </li>
          <li>
            Müll und Recycling
          </li>
        </ul>
      </div>

    </div>

  </div>

  <div class="w-full 2xl:hidden h-fit flex flex-col px-6"> <!--  -->
    <h1 class="text-gray-200 text-2.5xl w-full h-24 flex place-items-center place-content-start font-semibold">Unsere Roadmap</h1>
<!--    <p class="text-gray-400 text-2xl w-full text-center">Leider ist unsere Roadmap derzeit nur am PC sichtbar. Wir arbeiten an einer Handy Version!</p>-->

    <section class="text-gray-200">
      <div class="container w-full pb-12 mx-auto">
        <div class="grid gap-4 ml-4 sm:grid-cols-12">
          <div class="relative col-span-12 pl-2 space-y-6">
            <div class="col-span-12 space-y-12 relative pl-4 before:absolute before:top-2 before:bottom-1 before:w-1.5 before:-left-3.5 before:bg-gray-700">
              <div class="flex flex-col relative before:absolute before:top-2 before:w-4 before:h-4 before:rounded-full before:left-[-35px] before:z-[1] before:bg-lumina-medium-purple">

                <h3 class="text-xl font-semibold tracking-wide">Closed Alpha </h3>
                <time class="text-xs tracking-wide uppercase text-gray-400">Mitte 2023</time>

                <div class="flex w-full pt-2">
                  <div class="accordion flex flex-col gap-4 w-full" id="accordionRoadmap">

                    <div v-for="(chapter, index) in closedAlpha" class="accordion-item bg-glass">
                      <h2 class="accordion-header mb-0" :id="'roadmap-header' + index">
                        <button class="accordion-button relative flex items-center w-full py-4 px-5 text-xl text-gray-200 text-left rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#roadmap-collapse' + index" aria-expanded="false" :aria-controls="'roadmap-collapse' + index">
                          {{ chapter.title }}
                        </button>
                      </h2>
                      <div :id="'roadmap-collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'roadmap-header' + index">
                        <div class="accordion-body py-4 px-5 text-lg text-gray-200 flex flex-col gap-6">
                          <div>
                            <ul class="text-gray-300 text-lg list-disc pl-8 flex flex-col gap-2">
                              <li v-for="item in chapter.items">{{ item }}</li>
<!--                              <li>Hallo</li>-->
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col relative before:absolute before:top-2 before:w-4 before:h-4 before:rounded-full before:left-[-35px] before:z-[1] before:bg-gray-500">
                <h3 class="text-xl font-semibold tracking-wide text-gray-400">Open Alpha</h3>
<!--                <time class="text-xs tracking-wide uppercase text-gray-400">to be announced</time>-->
              </div>
              <div class="flex flex-col relative before:absolute before:top-2 before:w-4 before:h-4 before:rounded-full before:left-[-35px] before:z-[1] before:bg-gray-500">
                <h3 class="text-xl font-semibold tracking-wide text-gray-400">Closed Beta</h3>
<!--                <time class="text-xs tracking-wide uppercase text-gray-400">to be announced</time>-->
              </div>
              <div class="flex flex-col relative before:absolute before:top-2 before:w-4 before:h-4 before:rounded-full before:left-[-35px] before:z-[1] before:bg-gray-500">
                <h3 class="text-xl font-semibold tracking-wide text-gray-400">Open Beta</h3>
<!--                <time class="text-xs tracking-wide uppercase text-gray-400">to be announced</time>-->
              </div>
              <div class="flex flex-col relative before:absolute before:top-2 before:w-4 before:h-4 before:rounded-full before:left-[-35px] before:z-[1] before:bg-gray-500">
                <h3 class="text-xl font-semibold tracking-wide text-gray-400">Release</h3>
<!--                <time class="text-xs tracking-wide uppercase text-gray-400">to be announced</time>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "roadMap",
  data() {
    return {
      closedAlpha: {
        survival: {
          title: 'Überleben des Spielers',
          items: ['Funktion von Essen und Trinken', 'Funktion von Atem und Ausdauer', 'Funktion von Verlust von Lebenspunkten', 'Inventar', 'Lebensmittelshops', 'Animationen für diverse Aktivitäten wie z.B. Essen', 'Items und ihre Funktionen']
        },
        economy: {
          title: 'Wirtschaftssystem Teil 1',
          items: ['Bankautomaten', 'Grundeinkommen', 'Minijobs']
        },
        vehicles: {
          title: 'Fahrzeugsystem Teil 1',
          items: ['Fahrzeug Funktionen', 'Gebrauchtwagenhändler', 'Tanksystem', 'Kaufbare Garagenplätze']
        },
        other: {
          title: 'Sonstiges',
          items: ['Soundeffekte für diverse Features', 'Motels und Hotels', 'Spieler Interaktionsmenü', 'Müll und Recycling']
        },
      }
    }
  }
}

</script>

<style scoped>

.gradient-top {
  background: rgb(0,0,0);
  /*background: linear-gradient(-45deg, rgba(197, 15, 133, 0.4) 0%, transparent 100%);*/
  background: linear-gradient(135deg, rgba(102, 45, 145, 0.5) 0%, transparent 35%);

}

.roadmap-card.first:after {
  content: "";
  position: absolute;
  top: 100%;
  left: calc((100% - 80rem)/2 + 0.75rem);
  margin-left: -3rem;
  border-width: 3rem;
  border-style: solid;
  border-color: #1e2432 transparent transparent transparent;
}
.roadmap-card.second:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 29.5%;
  margin-left: -6rem;
  border-width: 6rem;
  border-style: solid;
  border-color: #1e2432 transparent transparent transparent;
}
.roadmap-card.third:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6rem;
  border-width: 6rem;
  border-style: solid;
  border-color: #1e2432 transparent transparent transparent;
}
.roadmap-card.fourth:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 25%;
  margin-left: -6rem;
  border-width: 6rem;
  border-style: solid;
  border-color: #1e2432 transparent transparent transparent;
}
.roadmap-card.fith:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 3.5%;
  margin-left: -6rem;
  border-width: 6rem;
  border-style: solid;
  border-color: #1e2432 transparent transparent transparent;
}

/*.dot {*/
/*  !*bottom: calc(12rem - 0.25rem);*!*/
/*  bottom: calc((100% - 40rem)/2 - 2rem - 0.25rem);*/
/*}*/

.dot:nth-of-type(3) /*Stupid broken shit because nth-of-class does not exist*/
{
  left: calc(((100% - 80rem)/2) + 20rem);
}
.dot:nth-of-type(5) /*Stupid broken shit because nth-of-class does not exist*/
{
  right: calc(((100% - 80rem)/2) + 20rem);
}
.dot:nth-of-type(6) /*Stupid broken shit because nth-of-class does not exist*/
{
  right: calc((100% - 80rem)/2);
}

.line {
  /*bottom: calc((100% - 40rem)/2 - 2rem);*/
}

.large-dot {
  /*bottom: calc((100% - 40rem)/2 - 2rem - 0.5rem);*/
  left: calc((100% - 80rem)/2);
}

.chapter{
  bottom: calc(50% + 1rem);
}

.chapter-first {
  left: calc(16% - 5.5rem);
}
.chapter-second {
  /*left: calc(33.333333% - 5.5rem) ;*/
  left: calc(((100% - 80rem)/2) + 20rem - 6rem);
}
.chapter-fourth {
  right: calc(((100% - 80rem)/2) + 20rem - 6rem);
}
.chapter-fith {
  right: calc(((100% - 80rem)/2) - 6rem);
}

.roadmap-card {
  /* From https://css.glass */
  background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5rem);
  /*-webkit-backdrop-filter: blur(5px);*/
}


.accordion-button:not(.collapsed) {
  color: #fff;
  background: linear-gradient(to bottom right, #662d91, #821690);
  box-shadow: none;
}

/*.accordion-item:first-of-type .accordion-button {*/

/*}*/

/*.accordion-item:last-of-type .accordion-button.collapsed {*/

/*}*/

/*.accordion-item:last-of-type:not(.collapsed) {*/
/*  margin-bottom: 4rem;*/
/*}*/

/*.accordion-item .accordion-button {*/
/*  border-radius: calc(0.5rem - 1px);*/
/*  !*border-top-right-radius: calc(0.5rem - 1px);*!*/
/*}*/

.accordion-button:not(.collapsed)::after {
  filter: brightness(200);
}

.accordion-button::after {
  filter: brightness(200);
}

.bg-glass {
  /* From https://css.glass */
  background: linear-gradient(225deg, rgba(252, 70, 107, 0.1) 0%, rgba(63, 94, 251, 0.1) 100%);
  background: rgba(108, 108, 111, 0.15);
  box-shadow: 0 4px 5rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.5rem);
  /*-webkit-backdrop-filter: blur(5px);*/
}


</style>