import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FAQView from "@/views/FAQView";
import ComingSoonView from "@/views/ComingSoonView";
import privacyView from "@/views/PrivacyView";
import impressumView from "@/views/ImpressumView";
import ConceptView from "@/views/ConceptView";
import jobView from "@/views/JobView";

import pageNotFoundView from "@/views/PageNotFoundView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/concept',
    name: 'concept',
    component: ConceptView
  },
  {
    path: '/voluntary',
    name: 'voluntary',
    component: jobView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQView
  },
  {
    path: '/mod-credits',
    name: 'mod-credits',
    component: ComingSoonView
  },
  {
    path: '/community-guideline',
    name: 'community-guideline',
    component: ComingSoonView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: ComingSoonView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacyView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: impressumView
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: pageNotFoundView
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
