<template>
  <div id="ImpressumView" class="w-screen h-screen select-none overflow-x-hidden bg-gray-900 relative">
    <nav-bar></nav-bar>
    <impressum-section></impressum-section>
    <footer-section></footer-section>
  </div>
</template>

<script>
import footerSection from "@/components/sections/footerSection";
import navBar from "@/components/sections/navBar";
import impressumSection from "@/components/sections/impressumSection";

export default {
  name: 'ImpressumView',
  components: {
    footerSection,
    navBar,
    impressumSection,
  }
}
</script>