<template>
  <div id="ConceptView" class="w-screen h-full select-none overflow-x-hidden bg-gray-900 relative">
      <nav-bar></nav-bar>
      <page-not-found></page-not-found>
      <footer-section></footer-section>
  </div>
</template>

<script>
import footerSection from "@/components/sections/footerSection";
import navBar from "@/components/sections/navBar";
import pageNotFound from "@/components/errors/pageNotFound";

export default {
  name: "PageNotFoundView",
  components: {
    footerSection,
    navBar,
    pageNotFound
  }
}
</script>

<style scoped>

</style>